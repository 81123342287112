import Cookies from 'js-cookie';
import { axiosRequest } from '../../services/ApiCall.js';
import { toast } from 'react-toastify';

export const UserAuthApi = {
    userRegister: async (payload, navigate) => {
        try {
            const response = await axiosRequest.post(`/auth/register`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 201) { // Check for successful registration
                Cookies.set('audeet', response.data.token, { expires: 7 });
                toast.success(response?.data?.message || 'Registration Successful!');
                navigate('/');
                return response.data;
            } else {
                // Handle other status codes (e.g., 400 for validation errors)
                toast.error(response?.data?.message || 'Registration Failed! Please Try Again Later');
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Registration Failed! Please Try Again Later');
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    userLogin: async (payload, navigate) => {
        try {
            const response = await axiosRequest.post(`/auth/login`, payload);
            if (response.status === 200) { // Check for successful login
                Cookies.set('audeet', response.data.token, { expires: 7 });
                toast.success(response?.data?.message || 'Login Successful!');
                navigate('/');
                return response.data;
            } else {
                // Handle other status codes (e.g., 400 for validation errors)
                toast.error(response?.data?.message || 'Login Failed! Please Try Again Later');
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Login Failed! Please Try Again Later');
            throw error;
        }
    },
    getUser: async () => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.get(`/auth/get-profile`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    userEditProfile: async (payload) => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.put(`/auth/edit-profile`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response) {
                return response.data.user;
            }
        } catch (error) {
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    userLogout: async () => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.get(`/auth/logout`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Candidate Logout:', error);
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
};
