import React, { useState, useEffect } from 'react'
import './customer.css'
import Sidenav from '../../components/Sidenav/Sidenav'
import CSheader from '../../components/CSheader/CSheader'
import Customercard from '../CustomerCard/Customercard'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { AddCustomerRequest, GetAllCustomerRequest } from '../../redux/actions/CustomerAction'
import validator from 'validator'
import { toast } from 'react-toastify'
import { SyncLoader } from "react-spinners";

const Customer = () => {

    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [email, setEmail] = useState('');
    const [gst, setGst] = useState('');
    const [state, setState] = useState('');
    const [address, setAddress] = useState('');
    const [paymentDirection, setPaymentDirection] = useState('');
    const [amount, setAmount] = useState('');

    const handleAddCustomerOpen = () => {
        setOpen(true);
    };

    const handleAddCustomerClose = () => {
        setOpen(false);
    };

    const dispatch = useDispatch(); // Initialize useDispatch

    const { loading, Customers } = useSelector((state) => state.CustomerReducer);

    useEffect(() => {
        dispatch(GetAllCustomerRequest());
    }, [dispatch]);

    const handleAddCustomer = async (e) => {
        e.preventDefault();
        try {
            const customerData = {
                name,
                phoneNo,
                email,
                gst,
                state,
                address,
                paymentDirection,
                amount,
                toBeCollected: paymentDirection === 'To be collected from customer' ? true : false, // Set to true if paymentDirection is 'To be collected from customer', otherwise false
            };

            // Validations
            if (!name || !phoneNo || !email || !gst || !state || !address || !paymentDirection || !amount) {
                toast.error("All fields are required");
            } else if (!validator.isMobilePhone(phoneNo, 'en-IN')) {
                toast.error('Invalid Phone Number');
            } else if (!validator.isEmail(email)) {
                toast.error("Invalid Email");
            } else {
                dispatch(AddCustomerRequest(customerData));
                handleAddCustomerClose();
            }
        } catch (error) {
            console.error(`${error?.response?.data?.error || 'Something Went Wrong'}`);
            // toast.error(`${error?.response?.data?.error || 'Something Went Wrong'}`);
        }
    }

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: "#F7F7F7"
        }}>
            <SyncLoader color="#011135" />
        </div>
    }

    return (
        <div className='customer'>
            <div className="customer-cont">
                <div className="left-customer">
                    <Sidenav />
                </div>
                <div className="right-customer">

                    {Customers?.length > 0 ? (
                        <Customercard handleAdd={handleAddCustomerOpen} />
                    ) : (
                        <div className='rcust-home-cont'>
                            <CSheader />
                            <div className='rcust-home-img-cont'>
                                <img src='/assests/customer home.png' alt='' />
                            </div>
                            <div className='rcust-home-text-cont' >
                                <h1>Add your first Customer!</h1>
                                <h3>Add your different customer and effortlessly track payment in and sale returns</h3>
                                <button onClick={handleAddCustomerOpen}>ADD +</button>
                            </div>
                        </div>
                    )}

                </div>

            </div>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true} // Set to true to make the dialog take up the full width
                maxWidth="xs"
            >
                <DialogContent>
                    <div className='custform-single-cont'>
                        <label>
                            Customer/ Business
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className='custform-single-cont'>
                        <label>
                            Phone no.
                            <input
                                type="tel"
                                value={phoneNo}
                                onChange={(e) => setPhoneNo(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className='custform-single-cont'>
                        <label>
                            Email Address
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>
                    </div>

                    <div className='custform-single-cont'>
                        <label>
                            GSTIN
                            <input
                                type="text"
                                value={gst}
                                onChange={(e) => setGst(e.target.value)}
                            />
                        </label>
                        <label>
                            State
                            <input
                                type="text"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className='custform-single-cont'>
                        <label>
                            Billing /shipping address
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className='custform-bott-popup-cont'>
                        <label>
                            <input
                                type="radio"
                                name="paymentDirection"
                                checked={paymentDirection === 'To be collected from customer'}
                                onChange={() => setPaymentDirection('To be collected from customer')}
                            />
                            To be collected from customer
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="paymentDirection"
                                checked={paymentDirection === 'To be paid to customer'}
                                onChange={() => setPaymentDirection('To be paid to customer')}
                            />
                            To be paid to customer
                        </label>
                        <label>
                            <input
                                type="text"
                                placeholder='Enter the Amount'
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </label>
                    </div>

                    <div className='custform-single-cont'>
                        <button onClick={handleAddCustomer}>Save</button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Customer