import React, { useEffect } from 'react'
import './invoice.css'
import Sidenav from '../../components/Sidenav/Sidenav'
import { Link } from 'react-router-dom'
import Invoicecard from '../Invoicecard/Invoicecard'
import { useSelector, useDispatch } from "react-redux";
import { GetAllInvoiceRequest } from '../../redux/actions/InvoiceAction'
import { SyncLoader } from "react-spinners";

const Invoice = () => {

    const { Invoice, loading } = useSelector((state) => state.InvoiceReducer);

    const dispatch = useDispatch(); // Initialize useDispatch

    useEffect(() => {
        dispatch(GetAllInvoiceRequest());
    }, [dispatch]);

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: "#F7F7F7"
        }}>
            <SyncLoader color="#011135" />
        </div>
    }

    return (
        <div className='invoice'>
            <div className="invoice-cont">
                <div className="left-invoice">
                    <Sidenav />
                </div>
                <div className="right-invoice">

                    {/* invoice home for first entry */}
                    {Invoice?.invoice?.length > 0 ? (
                        <Invoicecard />
                    ) : (
                        <div className='right-invoice-home'>
                            <div className="rinvoice-home-header"></div>
                            <div className='rinvoice-home-img-cont'>
                                <img src="/assests/invoice.jpg" alt="" />
                            </div>
                            <h1>Hello! Create your first invoice</h1>
                            <h4>Click on the create invoice button below</h4>
                            <Link to="/create-invoice" className='invoice-link'><button className='rinvoice-home-btn'>Create Invoice+</button></Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Invoice