import { axiosRequest } from '../../services/ApiCall.js';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export const ProductApi = {
    addProductReq: async (payload) => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.post(`/product/add`, payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            if (response) {
                toast.success(response?.data?.message);
                return response.data;
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Add Product Failed! Please Try Again Later');
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    getAllProductReq: async () => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.get(`/product/getall`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.products;
        } catch (error) {
            console.error('Error Fetching In Product', error);
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    getProductReq: async () => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.get(`/product/:id`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error Fetching In Product', error);
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    updateProductStatus: async (payload) => {
        try {
            const token = Cookies.get("audeet");
            console.log('payload', payload);
            const response = await axiosRequest.put(`/product/update/status`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response) {
                toast.success(response?.data?.message);
                return response.data;
            }
        } catch (error) {
            // console.error('Edit company profile: Error', error);
            toast.error(error?.response?.data?.message || 'Update Payment Status! Please Try Again Later');
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
};
