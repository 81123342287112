import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import "./faq.scss";

export const FAQ = () => {
  return (
    <div className="FAQ-cont" id="FAQ">
      <div className="FAQ">
        <div className="left">
          <h1>
            FA<span>Q</span>
          </h1>
          <h4>Frequently Asked Questions</h4>
          <div className="accordian">
            <Accordion className="acc-child">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  1. What is Audeet and how can it benefit my business?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Audeet is an innovative solution designed to enhance business efficiency through features like rapid invoicing and sales management, providing streamlined processes for optimal performance.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              className="acc-child"
              sx={{
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  2. How does Audeet's AI-based GST/VAT/Income Tax reconciliation work?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Audeet's AI-based GST/VAT/Income Tax reconciliation utilizes advanced algorithms to automate the reconciliation process, ensuring accurate compliance with tax regulations.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="acc-child"
              sx={{
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>
                  3. Can Audeet integrate with existing accounting systems?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes, Audeet seamlessly integrates with popular accounting systems, offering a smooth transition and compatibility with your existing financial infrastructure.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="acc-child"
              sx={{
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Typography>
                  4. How does the auto-reminder for payment feature work?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Audeet's auto-reminder for payment feature automates the process of sending timely payment reminders through SMS, email, or WhatsApp, facilitating effective cash flow management and reducing the risk of late payments.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="acc-child"
              sx={{
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5a-content"
                id="panel5a-header"
              >
                <Typography>
                  5. Is Audeet suitable for businesses of all sizes?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Audeet is scalable and adaptable, catering to businesses of all sizes, from small enterprises to larger corporations, providing tailored solutions based on unique business requirements.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="acc-child"
              sx={{
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6a-content"
                id="panel6a-header"
              >
                <Typography>6. What measures does Audeet take to ensure data privacy and security?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Audeet prioritizes data privacy and security, implementing measures such as encryption protocols to protect sensitive information and automated processes for identifying and removing personal data, ensuring a secure and compliant environment.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        {/* <div className="right">
          <img src="./assests/FAQ.gif" alt="" />
          <img src="/assests/Group 239.gif" alt="" style={{ width: "100%" }} className="faq-right-comp-img" />
          <img src="/assests/Frame 364.gif" alt="" style={{ width: "100%" }} className="faq-right-mob-img" />
        </div> */}
      </div>
    </div>
  );
};
