import {
    ADD_CUSTOMER_FAIL,
    ADD_CUSTOMER_REQUEST,
    ADD_CUSTOMER_SUCCESS,
    GET_ALL_CUSTOMER_FAIL,
    GET_ALL_CUSTOMER_REQUEST,
    GET_ALL_CUSTOMER_SUCCESS,
    GET_CUSTOMER_FAIL,
    GET_CUSTOMER_REQUEST,
    GET_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_STATUS_FAIL,
    UPDATE_CUSTOMER_STATUS_REQUEST,
    UPDATE_CUSTOMER_STATUS_SUCCESS
} from '../constants/CustomerConstant';

export const AddCustomerRequest = (customerData) => ({
    type: ADD_CUSTOMER_REQUEST,
    payload: { customerData }
});

export const AddCustomerSuccess = (data) => ({
    type: ADD_CUSTOMER_SUCCESS,
    data,
});

export const AddCustomerFailure = (error) => ({
    type: ADD_CUSTOMER_FAIL,
    error,
});

export const GetCustomerRequest = (payload) => ({
    type: GET_CUSTOMER_REQUEST,
    payload,
});

export const GetCustomerSuccess = (data) => ({
    type: GET_CUSTOMER_SUCCESS,
    data,
});

export const GetCustomerFailure = (error) => ({
    type: GET_CUSTOMER_FAIL,
    error,
});

export const GetAllCustomerRequest = () => ({
    type: GET_ALL_CUSTOMER_REQUEST,

});

export const GetAllCustomerSuccess = (data) => ({
    type: GET_ALL_CUSTOMER_SUCCESS,
    data,
});

export const GetAllCustomerFailure = (error) => ({
    type: GET_ALL_CUSTOMER_FAIL,
    error,
});

export const UpdateCustomerStatusRequest = (customerData) => ({
    type: UPDATE_CUSTOMER_STATUS_REQUEST,
    payload: { customerData }
});

export const UpdateCustomerStatusSuccess = (data) => ({
    type: UPDATE_CUSTOMER_STATUS_SUCCESS,
    data,
});

export const UpdateCustomerStatusFailure = (error) => ({
    type: UPDATE_CUSTOMER_STATUS_FAIL,
    error,
});