import {
    ADD_SUPPLIER_FAIL,
    ADD_SUPPLIER_REQUEST,
    ADD_SUPPLIER_SUCCESS,
    GET_ALL_SUPPLIER_FAIL,
    GET_ALL_SUPPLIER_REQUEST,
    GET_ALL_SUPPLIER_SUCCESS,
    GET_SUPPLIER_FAIL,
    GET_SUPPLIER_REQUEST,
    GET_SUPPLIER_SUCCESS,
    UPDATE_SUPPLIER_STATUS_FAIL,
    UPDATE_SUPPLIER_STATUS_REQUEST,
    UPDATE_SUPPLIER_STATUS_SUCCESS
} from '../constants/SupplierConstant';

export const AddSupplierRequest = (supplierData) => ({
    type: ADD_SUPPLIER_REQUEST,
    payload: { supplierData }
});

export const AddSupplierSuccess = (data) => ({
    type: ADD_SUPPLIER_SUCCESS,
    data,
});

export const AddSupplierFailure = (error) => ({
    type: ADD_SUPPLIER_FAIL,
    error,
});

export const GetSupplierRequest = (payload) => ({
    type: GET_SUPPLIER_REQUEST,
    payload,
});

export const GetSupplierSuccess = (data) => ({
    type: GET_SUPPLIER_SUCCESS,
    data,
});

export const GetSupplierFailure = (error) => ({
    type: GET_SUPPLIER_FAIL,
    error,
});

export const GetAllSupplierRequest = () => ({
    type: GET_ALL_SUPPLIER_REQUEST,
});

export const GetAllSupplierSuccess = (data) => ({
    type: GET_ALL_SUPPLIER_SUCCESS,
    data,
});

export const GetAllSupplierFailure = (error) => ({
    type: GET_ALL_SUPPLIER_FAIL,
    error,
});

export const UpdateSupplierStatusRequest = (supplierData) => ({
    type: UPDATE_SUPPLIER_STATUS_REQUEST,
    payload: { supplierData }
});

export const UpdateSupplierStatusSuccess = (data) => ({
    type: UPDATE_SUPPLIER_STATUS_SUCCESS,
    data,
});

export const UpdateSupplierStatusFailure = (error) => ({
    type: UPDATE_SUPPLIER_STATUS_FAIL,
    error,
});