import React from "react";
import "./footer.css";
import { FaTwitter, FaLinkedin, FaWhatsapp, FaEnvelope, FaPhone } from "react-icons/fa6";
import { ImArrowUpRight2 } from "react-icons/im";

export const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-cont">
                <div className="ftop">
                    <div className="fleft_top">
                        <div className="fleft_top-img-cont">
                            <img src="/assests/footerlogo.png" alt="" />
                        </div>
                        <h5>Audeet is an accounting website that simplifies your financial related problems</h5>
                    </div>
                    <div className="fmid_top">
                        <h4>
                            Quick Links
                        </h4>
                        <div className="fmid_top_list">
                            <span>Solutions</span>
                            <span>Features</span>
                            <span>Why Audeet</span>
                            <span>Faq</span>
                        </div>
                    </div>
                    <div className="fright_top">
                        <h4>
                            Contact Information
                        </h4>
                        <div className="fright_top_list">
                            <div className="fright_top_list_cont">
                                <button><FaEnvelope /></button>
                                <div className="fright_top_list_text">
                                    <span>Email</span>
                                    <span>info@audeet.com</span>

                                </div>

                            </div>
                            <div className="fright_top_list_cont">
                                <button><FaPhone /></button>
                                <div className="fright_top_list_text">
                                    <span>Phone</span>
                                    <span>0123456789</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fbottom">
                    <div className="fbottom_left">
                        <h2>Get The Latest News And Updates,</h2>
                        <h2> Subscribe Now!</h2>
                        <div className="fbottom_left_input_cont">
                            <input placeholder="Your Email Address" />
                            <button><ImArrowUpRight2 /></button>
                        </div>
                    </div>
                    <div className="fbottom_right">
                        <span><FaTwitter /></span>
                        <span><FaLinkedin /></span>
                        <span><FaWhatsapp /></span>

                    </div>
                </div>
            </div>
        </div>

    );
};
