export const ADD_CUSTOMER_REQUEST = "ADD_CUSTOMER_REQUEST";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL";

export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAIL = "GET_CUSTOMER_FAIL";

export const GET_ALL_CUSTOMER_REQUEST = "GET_ALL_CUSTOMER_REQUEST";
export const GET_ALL_CUSTOMER_SUCCESS = "GET_ALL_CUSTOMER_SUCCESS";
export const GET_ALL_CUSTOMER_FAIL = "GET_ALL_CUSTOMER_FAIL";

export const UPDATE_CUSTOMER_STATUS_REQUEST = "UPDATE_CUSTOMER_STATUS_REQUEST";
export const UPDATE_CUSTOMER_STATUS_SUCCESS = "UPDATE_CUSTOMER_STATUS_SUCCESS";
export const UPDATE_CUSTOMER_STATUS_FAIL = "UPDATE_CUSTOMER_STATUS_FAIL";