import React from 'react'
import './offer.css'
import { FaQuoteLeft } from "react-icons/fa";

const Offer = () => {
    return (
        <div className='offer'>
            <div className="offer-heading">
                <h3>What <span>Features</span> We Offer</h3>
            </div>
            <div className="offer-cards">
                <div className="offer-card">
                    <h1><FaQuoteLeft /></h1>
                    <h4>Sales ,Purchase & Expense</h4>
                </div>
                <div className="offer-card">
                    <h1><FaQuoteLeft /></h1>
                    <h4>AI-based GST / VAT / Income Tax reconciliation</h4>
                </div>
                <div className="offer-card">
                    <h1><FaQuoteLeft /></h1>
                    <h4>AI-Based Balance Sheet Generation with tamper-proof technology</h4>
                </div>
                <div className="offer-card">
                    <h1><FaQuoteLeft /></h1>
                    <h4>Auto Reminder for Payment</h4>
                </div>
                <div className="offer-card">
                    <h1><FaQuoteLeft /></h1>
                    <h4>Inventory management</h4>
                </div>
            </div>
        </div>
    )
}

export default Offer