export const INVOICE_GENERATE_REQUEST="INVOICE_GENERATE_REQUEST";
export const INVOICE_GENERATE_SUCCESS="INVOICE_GENERATE_SUCCESS";
export const INVOICE_GENERATE_FAIL="INVOICE_GENERATE_FAIL";

export const GET_INVOICE_REQUEST="GET_INVOICE_REQUEST";
export const GET_INVOICE_SUCCESS="GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAIL="GET_INVOICE_FAIL";

export const GET_ALL_INVOICE_REQUEST = "GET_ALL_INVOICE_REQUEST";
export const GET_ALL_INVOICE_SUCCESS = "GET_ALL_INVOICE_SUCCESS";
export const GET_ALL_INVOICE_FAIL = "GET_ALL_INVOICE_FAIL";