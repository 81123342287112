import { all } from 'redux-saga/effects';
import CustomerSaga from './CustomerSaga';
import SupplierSaga from './SupplierSaga';
import ProductSaga from './ProductSaga';
import ServiceSaga from './ServiceSaga';
import UserAuthSaga from './AuthSaga';
import InvoiceSaga from './InvoiceSaga';

function* rootSaga() {
    yield all([
        UserAuthSaga(),
        CustomerSaga(),
        SupplierSaga(),
        ProductSaga(),
        ServiceSaga(),
        InvoiceSaga(),
    ]);
}

export default rootSaga;
