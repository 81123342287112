import { axiosRequest } from '../../services/ApiCall.js';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export const CustomerApi = {
    addCustomerReq: async (payload) => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.post(`/customer/add`, payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            if (response) {
                toast.success(response?.data?.message);
                return response.data;
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Add Customer Failed! Please Try Again Later');
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    getCustomerReq: async () => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.get(`/customer/:id`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error Fetching In Customer', error);
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    getAllCustomerReq: async () => {
        try {
            const token = Cookies.get("audeet");
            const response = await axiosRequest.get(`/customer/get-all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.customers;
        } catch (error) {
            console.error("Error Fetching In Customer", error);
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    updateCustomerStatus: async (payload) => {
        try {
            const token = Cookies.get("audeet");
            const response = await axiosRequest.put(`/customer/update/status`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response) {
                toast.success(response?.data?.message);
                return response.data;
            }
        } catch (error) {
            // console.error('Edit company profile: Error', error);
            toast.error(error?.response?.data?.message || 'Update Payment Status! Please Try Again Later');
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
};
