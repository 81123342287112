import React, { useEffect, useState } from 'react'
import "./sidenav.css"
import { FaArrowLeftLong, FaBars } from 'react-icons/fa6'
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'
// import { HiOutlineBars3 } from 'react-icons/hi2'
import { MdClose } from "react-icons/md";
import { LuUserPlus2 } from "react-icons/lu";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie';

const Sidenav = () => {

    const [isContentVisible, setIsContentVisible] = useState(false);
    const [activeButton, setActiveButton] = useState(null);
    const [isBars, setIsBars] = useState(true);
    const [showMenu, setShowMenu] = useState(false);
    const [initials, setInitials] = useState('');

    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.UserAuthReducer);


    // useEffect(() => {
    //     if (user && user?.businessName) {
    //         const h3Text = user?.businessName;
    //         const words = h3Text.split(' ');
    //         const firstLetters = words.map(word => {
    //             const firstChar = word.charAt(0);
    //             return /^[a-zA-Z0-9]/.test(firstChar) ? firstChar.toUpperCase() : '';
    //         }).join('');
    //         setInitials(firstLetters);
    //     }
    // }, [user]);
    useEffect(() => {
        if (user && user?.businessName) {
            const firstLetter = user?.businessName.charAt(0).toUpperCase();
            setInitials(firstLetter);
        }
    }, [user]);


    // const handleFabarClick = () => {
    //     setIsContentVisible(!isContentVisible);
    // }
    const handleFabarClick = () => {
        setIsContentVisible(prevState => !prevState);
    }

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    }

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const toggleSideNav = () => {
        // toggleMenu(); // Toggle the side navigation
        setIsBars(!isBars); // Toggle the FaBars/MdClose icon
    };

    // Function to handle logout
    const handleLogout = () => {
        // Delete "audeet" cookie
        Cookies.remove('audeet');
        // Navigate user to home page
        navigate('/');
    };

    return (
        <div className={`hdprofile ${isContentVisible ? 'show-sidebar' : ''}`}>
            {/* <div className='hdprofile'> */}

            <div className="sidenav-cont">
                <div className={`left-hdpro ${isContentVisible ? 'show-sidebar' : ''}`}>
                    {/* <div className="left-hdpro"> */}
                    <div className="left-hdpro-cont">
                        <div className="top-hleft-card">
                            {/* <Link to="/" className='hleftcard-link'>
                                <button className='hdpro-back-btn'><FaArrowLeftLong /></button>
                            </Link> */}
                            <div className="top-audeet-logo">
                                <img src="/assests/footerlogo.png" alt="" />
                            </div>
                            {/* <button className='hlc-bar-btn' onClick={handleFabarClick}><FaBars /></button> */}

                            <div className="navbar-toggle" onClick={toggleMenu} >
                                <button
                                    // onClick={handleCancel}
                                    onClick={toggleSideNav}
                                    className='hlc-bar-btn'
                                >
                                    {isBars ? (
                                        <FaBars />
                                    ) : (
                                        <MdClose />
                                    )}
                                </button>

                            </div>
                        </div>

                        <div className={`items ${showMenu ? 'open' : ''}`} >
                            {/* {isContentVisible && ( */}
                            <div className='bottom-sidnav-hlc'>

                                <div className="sidenav-logo">
                                    <img src="/assests/footerlogo.png" alt="" />
                                </div>

                                <div className="krinvilogo-img-cont">
                                    <div className="krinvi-logo">
                                        <div>{initials}</div>
                                    </div>
                                    <h3>{user?.businessName}</h3>
                                </div>

                                <div className="hlc-mid-bottom-combine">

                                    <div className="hlc-mid-cont">

                                        <Link to="/profile" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list sidenav-profile-setup ${location.pathname === '/profile' ? 'active' : ''
                                                    }`}
                                            >
                                                <img src="/assests/Customer.png" alt="" />  Profile Setup
                                            </button>
                                        </Link>

                                        <h6 className='sidenav-link-head'>Parties</h6>
                                        <Link to="/customer" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list ${location.pathname === '/customer' ? 'active' : ''
                                                    }`}
                                            >
                                                {/* <img src="/assests/Group 140.png" alt=""/> */}
                                                <span><LuUserPlus2 /></span>
                                                Customers
                                            </button>
                                        </Link>
                                        <Link to="/supplier" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list ${location.pathname === '/supplier' ? 'active' : ''
                                                    }`}
                                            >
                                                {/* <img src="/assests/Group 117.png" alt="" /> */}
                                                <img src="/assests/Group 117.svg" alt="" />
                                                Suppliers
                                            </button>
                                        </Link>

                                        <h6 className='sidenav-link-head'>Inventory</h6>
                                        <Link to="/products" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list ${location.pathname === '/products' ? 'active' : ''
                                                    }`}
                                            >
                                                <img src="/assests/Box.svg" alt="" />   Products
                                            </button>
                                        </Link>
                                        <Link to="/services" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list ${location.pathname === '/services' ? 'active' : ''
                                                    }`}
                                            >
                                                <img src="/assests/Service.svg" alt="" /> Services
                                            </button>
                                        </Link>

                                        <h6 className='sidenav-link-head'>Sale</h6>
                                        <Link to="/invoice" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list ${location.pathname === '/invoice' || location.pathname === '/create-invoice' || location.pathname === `/download-invoice/${id}` ? 'active' : ''
                                                    }`}
                                            >
                                                {/* <img src="/assests/File Invoice.png" alt="" /> */}
                                                <span><LiaFileInvoiceSolid /></span>
                                                Invoice Management
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="hlc-bottom-cont">
                                        {/* <Link to="/" className='hleftcard-link'> */}
                                        <button className='hdpro-logoutbtn' onClick={handleLogout}>Logout</button>
                                        {/* </Link> */}
                                    </div>
                                </div>

                            </div>
                            {/* )} */}
                        </div>
                    </div>

                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default Sidenav