import React, { useState } from "react";
import "./login.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import validator from "validator";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { UserLoginRequest } from "../../redux/actions/AuthAction";
import { toast } from "react-toastify";

export const Login = () => {

    const [passShow, setPassShow] = useState(false);
    const [phoneNo, setPhoneNo] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            if (!phoneNo) {
                toast.error("Phone number is required");
            } else if (phoneNo.length !== 10 || !/^[0-9]{10}$/.test(phoneNo)) {
                toast.error("Please enter a valid phone number");
            } else if (!validator.isMobilePhone(phoneNo, "en-IN")) {
                toast.error("Please enter a valid phone number");
            } else if (!password) {
                toast.error("Password is required");
            } else if (password.length < 6) {
                toast.error("Length of password should be greater than 6 Charecter");
            } else {
                const userData = {
                    phoneNo,
                    password,
                };
                dispatch(UserLoginRequest(userData, navigate));
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    return (
        <div className="login-container">
            <div className="left-part">
                <p>
                    "Audeet: Simplifying your accounting tasks with intuitive features,
                    seamless invoicing, and robust financial management tools."
                </p>
            </div>
            <div className="right-part ">
                <div className="login-container">
                    <form className="login-form">
                        <div className="form_input">
                            <label htmlFor="phoneno">Phone no</label>
                            <input
                                type="tel"
                                placeholder="Enter Your Phone No"
                                value={phoneNo}
                                onChange={(e) => setPhoneNo(e.target.value)}
                            />
                        </div>
                        <div className="form_input">
                            <label htmlFor="password">Password</label>
                            <div className="hnl-input-passicon-cont">
                                <input
                                    type={!passShow ? "password" : "text"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    name="password"
                                    id="password"
                                    placeholder="Enter Your password"
                                />
                                <div
                                    className="hn-showpass"
                                    onClick={() => setPassShow(!passShow)}
                                >
                                    {!passShow ? <VscEyeClosed /> : <VscEye />}
                                </div>
                            </div>
                        </div>
                        <button className="hnlogin-signin-btn" onClick={handleLogin}>
                            Login
                        </button>
                        <div className="register-link">
                            Don't have an Account? <Link to="/register">Register</Link>
                        </div>
                        {/* <h2>Phone No</h2>
                        <input type="text" placeholder="Enter Phone Number"
                            onChange={(e) => setPhoneNumber(e.target.value)} />
                        <h2>Password</h2>
                        <input type="text" placeholder="Enter Phone Number"
                            onChange={(e) => setPhoneNumber(e.target.value)} />
                        <button type="submit">Continue</button> */}
                    </form>
                </div>
            </div>
        </div>
    );
};
