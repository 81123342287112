import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers"; // Import root reducer
import rootSaga from './sagas'; // Import root saga

let initialState = {};

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

// Use composeWithDevTools to enhance createStore
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(
    rootReducer,
    initialState,
    enhancer
);

sagaMiddleware.run(rootSaga) //run the saga

export default store;