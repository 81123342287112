import React from 'react'
import "./csheader.css"
import { Link, useLocation } from 'react-router-dom'

const CSheader = () => {

    const location = useLocation();

    return (
        <div className='csheader'>
            <div className="csheader-cont">
                <Link to="/customer" className='csheader-link'>
                    <button
                        className={`csheader-btn ${location.pathname === '/customer' ? 'active' : ''
                            }`}
                    >
                     Customers
                    </button>
                </Link>
                <Link to="/supplier" className='csheader-link'>
                    <button
                        className={`csheader-btn ${location.pathname === '/supplier' ? 'active' : ''
                            }`}
                    >
                    Suppliers
                    </button>
                </Link>

            </div>
        </div>
    )
}

export default CSheader