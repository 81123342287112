import { all, takeLatest, call, put } from 'redux-saga/effects';
import { ProductApi } from '../api/ProductApi';
import {
    ADD_PRODUCT_FAIL,
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_SUCCESS,
    GET_ALL_PRODUCT_FAIL,
    GET_ALL_PRODUCT_REQUEST,
    GET_ALL_PRODUCT_SUCCESS,
    GET_PRODUCT_FAIL,
    GET_PRODUCT_REQUEST,
    GET_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_STOCK_STATUS_FAIL,
    UPDATE_PRODUCT_STOCK_STATUS_REQUEST,
    UPDATE_PRODUCT_STOCK_STATUS_SUCCESS
} from '../constants/ProductConstant';

function* AddProductSagaWorker(action) {
    try {
        const { productData } = action.payload;
        const result = yield call(ProductApi.addProductReq, productData);
        yield put({ type: ADD_PRODUCT_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: ADD_PRODUCT_FAIL, error });
    }
}

function* GetAllProductSagaWorker(action) {
    try {
        const result = yield call(ProductApi.getAllProductReq, action.payload);
        yield put({ type: GET_ALL_PRODUCT_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: GET_ALL_PRODUCT_FAIL, error });
    }
}

function* GetProductSagaWorker(action) {
    try {
        const result = yield call(ProductApi.getProductReq, action.payload);
        yield put({ type: GET_PRODUCT_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: GET_PRODUCT_FAIL, error });
    }
}

function* UpdateProductStatusSagaWorker(action) {
    try {
        const { productData } = action.payload;
        const result = yield call(ProductApi.updateProductStatus, productData);
        yield put({ type: UPDATE_PRODUCT_STOCK_STATUS_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: UPDATE_PRODUCT_STOCK_STATUS_FAIL, error });
    }
}

function* ProductSagaWatcher() {
    yield takeLatest(ADD_PRODUCT_REQUEST, AddProductSagaWorker);
    yield takeLatest(GET_ALL_PRODUCT_REQUEST, GetAllProductSagaWorker);
    yield takeLatest(GET_PRODUCT_REQUEST, GetProductSagaWorker);
    yield takeLatest(UPDATE_PRODUCT_STOCK_STATUS_REQUEST, UpdateProductStatusSagaWorker);
}

export default function* ProductSaga() {
    yield all([ProductSagaWatcher()]);
}
