import { all, takeLatest, call, put } from 'redux-saga/effects';
import { InvoiceApi } from '../api/InvoiceApi';
import {
    GET_ALL_INVOICE_FAIL,
    GET_ALL_INVOICE_REQUEST,
    GET_ALL_INVOICE_SUCCESS,
    GET_INVOICE_FAIL,
    GET_INVOICE_REQUEST,
    GET_INVOICE_SUCCESS,
    INVOICE_GENERATE_FAIL,
    INVOICE_GENERATE_REQUEST,
    INVOICE_GENERATE_SUCCESS
} from '../constants/InvoiceConstant';

// function* generateInvoiceSagaWorker(action) {
//     try {
//         const { invoiceData } = action.payload;
//         const result = yield call(InvoiceApi.generateInvoice, invoiceData);
//         yield put({ type: INVOICE_GENERATE_SUCCESS, data: result });
//     } catch (error) {
//         yield put({ type: INVOICE_GENERATE_FAIL, error });
//     }
// }
function* generateInvoiceSagaWorker(action) {
    try {
        const { invoiceData } = action.payload;
        
        // Log the invoice data before making the API call
        console.log("Invoice data:", invoiceData);

        // Make the API call to generate the invoice
        const result = yield call(InvoiceApi.generateInvoice, invoiceData);
        
        // Log the result after the API call
        console.log("Invoice generation result:", result);
        
        // Dispatch a success action with the result
        yield put({ type: INVOICE_GENERATE_SUCCESS, data: result });
    } catch (error) {
        // If an error occurs, log the error and dispatch a failure action
        console.error("Error generating invoice:", error);
        yield put({ type: INVOICE_GENERATE_FAIL, error });
    }
}

function* getInvoiceSagaWorker(action) {
    try {
        const { id } = action.payload;
        const result = yield call(InvoiceApi.getInvoice, id);
        yield put({ type: GET_INVOICE_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: GET_INVOICE_FAIL, error });
    }
}

function* GetAllInvoiceSagaWorker(action) {
    try {
        const result = yield call(InvoiceApi.getAllInvoice);
        yield put({ type: GET_ALL_INVOICE_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: GET_ALL_INVOICE_FAIL, error });
    }
}

function* InvoiceSagaWatcher() {
    yield takeLatest(INVOICE_GENERATE_REQUEST, generateInvoiceSagaWorker);
    yield takeLatest(GET_INVOICE_REQUEST, getInvoiceSagaWorker);
    yield takeLatest(GET_ALL_INVOICE_REQUEST, GetAllInvoiceSagaWorker);
}

export default function* InvoiceSaga() {
    yield all([InvoiceSagaWatcher()]);
}