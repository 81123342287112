import React from 'react'
import "./future.css"
import { FaCheck } from "react-icons/fa6";

const Future = () => {

    return (
        <div className='future'>
            <div className='future-cont'>
                <div className="left-future">
                    <div className="ltop-future">
                        <h3>Future of <span>E-Invoicing</span></h3>
                        <h3>is here</h3>
                        <h5>Our AI-powered solutions are meticulously crafted to address key challenges faced by businesses today</h5>
                        <div className="ltop-future-list">
                            <span><button className='future-checkbox'><FaCheck /></button>Streamlined Payment Cycles</span>
                            <span><button className='future-checkbox'><FaCheck /></button>AI-Driven Score for Timely Payments</span>
                            <span><button className='future-checkbox'><FaCheck /></button>Efficient Automation of Compliance Reports</span>
                            <span><button className='future-checkbox'><FaCheck /></button>Robust Data Encryption and Anonymization</span>
                            <span><button className='future-checkbox'><FaCheck /></button>AI and Blockchain for Tax Evasion Detection</span>
                        </div>
                    </div>
                </div>

                <div className="right-future">
                    <img src="/assests/rafiki.png" alt="" />
                </div>

            </div>
        </div>
    )
}

export default Future