import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Home } from "./pages/home/Home";
import { Login } from "./pages/login/Login";
import { Register } from "./pages/register/Register";
import { Otp } from "./components/otp/Otp";
import Profile from "./pages/profile/Profile";
import Editprofile from "./pages/Editprofile/Editprofile";
import Customer from "./pages/customer/Customer";
import Product from "./pages/product/Product";
import Service from "./pages/services/Service";
import Supplier from "./pages/supplier/Supplier";
import Invoice from "./pages/invoice/Invoice";
import Createinvoice from "./pages/CreateInvoice/Createinvoice";
import DownloadInvoice from "./pages/DownloadInvoice/DownloadInvoice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUserRequest } from "./redux/actions/AuthAction";

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserRequest())
  }, [])

  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/otp' element={<Otp />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/edit-profile' element={<Editprofile />} />
          <Route path='/customer' element={<Customer />} />
          <Route path='/supplier' element={<Supplier />} />
          <Route path='/products' element={<Product />} />
          <Route path='/services' element={<Service />} />
          <Route path='/invoice' element={<Invoice />} />
          <Route path='/create-invoice' element={<Createinvoice />} />
          <Route path='/download-invoice/:id' element={<DownloadInvoice />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
