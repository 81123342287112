import React from 'react'
import "./banner.css"

const Banner = () => {
    return (
        <div className='banner'>
            <div className="banner-cont" >
                <div className="left-banner">
                    <div className='banner-head-cont'>
                        <h1>Daily Transaction Made Easier! </h1>
                        <h3>Elevate Success with Audeet by Blockchain & AI Powered Invoicing</h3>
                        <button>Get Started</button>
                    </div>
                </div>
                <div className="right-banner">
                    <img src="/assests/banner.png" alt="" />
                </div>
            </div>
        </div>
    )
}

export default Banner