import { all, takeLatest, call, put } from 'redux-saga/effects';
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_GET_REQUEST,
    USER_GET_SUCCESS,
    USER_GET_FAIL,
    USER_EDIT_PROFILE_REQUEST,
    USER_EDIT_PROFILE_FAIL,
    USER_EDIT_PROFILE_SUCCESS,
    USER_LOGOUT_REQUEST,
    USER_LOGOUT_SUCCESS,
    USER_LOGOUT_FAIL
} from '../constants/AuthConstant';

import { UserAuthApi } from '../api/AuthApi';

function* UserRegisterSagaWorker(action) {
    try {
        const { userData, navigate } = action.payload; // Extracting navigate from payload
        const result = yield call(UserAuthApi.userRegister, userData, navigate); // Pass navigate function
        yield put({ type: USER_REGISTER_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: USER_REGISTER_FAIL, error });
    }
}

function* UserLoginSagaWorker(action) {
    try {
        const { userData, navigate } = action.payload; // Extracting navigate from payload
        const result = yield call(UserAuthApi.userLogin, userData, navigate); // Pass navigate function
        yield put({ type: USER_LOGIN_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: USER_LOGIN_FAIL, error });
    }
}

function* GetUserSagaWorker(action) {
    try {
        const result = yield call(UserAuthApi.getUser, action.payload);
        yield put({ type: USER_GET_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: USER_GET_FAIL, error });
    }
}

function* UserEditSagaWorker(action) {
    try {
        const result = yield call(UserAuthApi.userEditProfile, action.payload);
        yield put({ type: USER_EDIT_PROFILE_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: USER_EDIT_PROFILE_FAIL, error });
    }
}

function* UserLogoutSagaWorker(action) {
    try {
        const result = yield call(UserAuthApi.userLogout, action.payload);
        yield put({ type: USER_LOGOUT_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: USER_LOGOUT_FAIL, error });
    }
}


function* UserAuthSagaWatcher() {
    yield takeLatest(USER_REGISTER_REQUEST, UserRegisterSagaWorker);
    yield takeLatest(USER_LOGIN_REQUEST, UserLoginSagaWorker);
    yield takeLatest(USER_GET_REQUEST, GetUserSagaWorker);
    yield takeLatest(USER_EDIT_PROFILE_REQUEST, UserEditSagaWorker);
    yield takeLatest(USER_LOGOUT_REQUEST, UserLogoutSagaWorker);
}

export default function* UserAuthSaga() {
    yield all([UserAuthSagaWatcher()]);
}

