import React, { useState } from 'react'
import './addservice.css'
import { TfiSearch } from 'react-icons/tfi';
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import Productheader from '../../components/ProductHeader/Productheader';
import { useSelector } from 'react-redux';

const Addservice = ({ handleAddService }) => {

    const { Service } = useSelector((state) => state.ServiceReducer);

    const [selectedService, setSelectedService] = useState(Service[0]);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredServices = Service.filter((service) => {
        // Perform case-insensitive search based on service name
        return service.serviceName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const handleSelectService = (service) => {
        setSelectedService(service);
    };

    return (
        <div className='addservice'>
            <div className="aser-cont">

                <div className="left-addser">

                    <div className='left-addser-cont'>
                        <Productheader />
                        <div className='laser-search-cont'>
                            <div className='laser-search-box'>
                                <span>
                                    <TfiSearch />
                                </span>
                                <input
                                    type='text'
                                    placeholder='Search'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='laser-card-loop' >
                            {/* {Service.map((service) => ( */}
                            {filteredServices.map((service) => (
                                <div className='laser-card' key={service._id} onClick={() => handleSelectService(service)}>
                                    <div className="leftaser-card">
                                        <div>
                                            <h5 className='left-aser-logo'>{service?.serviceName?.split(' ')[0].charAt(0).toUpperCase()}</h5>
                                            <button className='laser-edit-btn'>
                                                Edit <FaRegEdit />
                                            </button>
                                        </div>
                                        <div className='laser-lp-cont'>
                                            <h6>{service.serviceName}</h6>
                                            <p>{new Date(service.createdAt).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                        </div>
                                    </div>
                                    <div className="left-right-aser-card">
                                        <div className='laser-service-text-cont'>
                                            <span>Service</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className='lasc-add-service-btn'>
                            <button onClick={() => handleAddService()}>Add Service</button>
                        </div>
                    </div>

                </div>


                <div className="right-aser">
                    {/*  */}
                    <div className='raser-cont'>
                        <div className='top-raser' >
                            <div className="top-raser-cont">
                                <div className='tleft-raser-cont'>
                                    <h5>{selectedService?.serviceName?.split(' ')[0].charAt(0).toUpperCase()}</h5> <span>{selectedService.serviceName}</span>
                                </div>
                                <div className='tright-raser-cont'>
                                    <button> <FaRegEdit />Edit</button>
                                    <button><AiOutlineDelete /> Delete</button>
                                </div>
                            </div>
                        </div>

                        <div className='mid-raser' >
                            <div>
                                <span>Sale Price</span>
                                <strong>{selectedService.servicePrice} {selectedService.serviceUnit}</strong>
                            </div>
                            <div>
                                <span>Category</span>
                                <strong>{selectedService.serviceCategory}</strong>
                            </div>
                            <div>
                                <span>SAC Code</span>
                                <strong>{selectedService.HsnSacCode}</strong>
                            </div>
                        </div>


                    </div>
                </div>


            </div>
        </div>
    )
}

export default Addservice