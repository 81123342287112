import {
    ADD_PRODUCT_FAIL,
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_SUCCESS,
    GET_ALL_PRODUCT_FAIL,
    GET_ALL_PRODUCT_REQUEST,
    GET_ALL_PRODUCT_SUCCESS,
    GET_PRODUCT_FAIL,
    GET_PRODUCT_REQUEST,
    GET_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_STOCK_STATUS_FAIL,
    UPDATE_PRODUCT_STOCK_STATUS_REQUEST,
    UPDATE_PRODUCT_STOCK_STATUS_SUCCESS
} from '../constants/ProductConstant';

export const AddProductRequest = (productData) => ({
    type: ADD_PRODUCT_REQUEST,
    payload: { productData }
});

export const AddProductSuccess = (data) => ({
    type: ADD_PRODUCT_SUCCESS,
    data,
});

export const AddProductFailure = (error) => ({
    type: ADD_PRODUCT_FAIL,
    error,
});

export const GetAllProductRequest = (payload) => ({
    type: GET_ALL_PRODUCT_REQUEST,
    payload,
});

export const GetAllProductSuccess = (data) => ({
    type: GET_ALL_PRODUCT_SUCCESS,
    data,
});

export const GetAllProductFailure = (error) => ({
    type: GET_ALL_PRODUCT_FAIL,
    error,
});

export const GetProductRequest = (payload) => ({
    type: GET_PRODUCT_REQUEST,
    payload,
});

export const GetProductSuccess = (data) => ({
    type: GET_PRODUCT_SUCCESS,
    data,
});

export const GetProductFailure = (error) => ({
    type: GET_PRODUCT_FAIL,
    error,
});

export const UpdateProductStatusRequest = (productData) => ({
    type: UPDATE_PRODUCT_STOCK_STATUS_REQUEST,
    payload: { productData }
});

export const UpdateProductStatusSuccess = (data) => ({
    type: UPDATE_PRODUCT_STOCK_STATUS_SUCCESS,
    data,
});

export const UpdateProductStatusFailure = (error) => ({
    type: UPDATE_PRODUCT_STOCK_STATUS_FAIL,
    error,
});