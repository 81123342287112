import { all, takeLatest, call, put } from 'redux-saga/effects';
import { CustomerApi } from '../api/CustomerApi';
import {
    ADD_CUSTOMER_FAIL,
    ADD_CUSTOMER_REQUEST,
    ADD_CUSTOMER_SUCCESS,
    GET_ALL_CUSTOMER_FAIL,
    GET_ALL_CUSTOMER_REQUEST,
    GET_ALL_CUSTOMER_SUCCESS,
    GET_CUSTOMER_FAIL,
    GET_CUSTOMER_REQUEST,
    GET_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_STATUS_FAIL,
    UPDATE_CUSTOMER_STATUS_REQUEST,
    UPDATE_CUSTOMER_STATUS_SUCCESS
} from '../constants/CustomerConstant';

function* AddCustomerSagaWorker(action) {
    try {
        const { customerData } = action.payload;
        const result = yield call(CustomerApi.addCustomerReq, customerData);
        yield put({ type: ADD_CUSTOMER_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: ADD_CUSTOMER_FAIL, error });
    }
}

function* GetCustomerSagaWorker(action) {
    try {
        const result = yield call(CustomerApi.getCustomerReq, action.payload);
        yield put({ type: GET_CUSTOMER_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: GET_CUSTOMER_FAIL, error });
    }
}

function* GetAllCustomerSagaWorker(action) {
    try {
        const result = yield call(CustomerApi.getAllCustomerReq);
        yield put({ type: GET_ALL_CUSTOMER_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: GET_ALL_CUSTOMER_FAIL, error });
    }
}

function* UpdateCustomerStatusSagaWorker(action) {
    try {
        const { customerData } = action.payload;
        const result = yield call(CustomerApi.updateCustomerStatus, customerData);
        yield put({ type: UPDATE_CUSTOMER_STATUS_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: UPDATE_CUSTOMER_STATUS_FAIL, error });
    }
}

function* CustomerSagaWatcher() {
    yield takeLatest(ADD_CUSTOMER_REQUEST, AddCustomerSagaWorker);
    yield takeLatest(GET_CUSTOMER_REQUEST, GetCustomerSagaWorker);
    yield takeLatest(GET_ALL_CUSTOMER_REQUEST, GetAllCustomerSagaWorker);
    yield takeLatest(UPDATE_CUSTOMER_STATUS_REQUEST, UpdateCustomerStatusSagaWorker);
}

export default function* CustomerSaga() {
    yield all([CustomerSagaWatcher()]);
}

