import React from "react";
import "./home.scss";
import { Navbar } from "../../components/navbar/Navbar";
// import { Header } from "../../components/header/Header";
// import { Features } from "../../components/Features/Features";
import { WhyAudeet } from "../../components/whyAudeet/WhyAudeet";
// import { Aboutus } from "../../components/AboutUs/Aboutus";
import { Benifits } from "../../components/Benifits/Benifits";
// import { Intro } from "../../components/Introduction/Intro";
import { FAQ } from "../../components/FAQ/FAQ";
import { Footer } from "../../components/footer/Footer";
import Future from "../../components/future/Future";
import Explore from "../../components/explore/Explore";
import Banner from "../../components/banner/Banner";
import Offer from "../../components/Offer/Offer";

export const Home = () => {
  return (
    <div className="home">
      <Navbar />
      <Banner />
      {/* <Header /> */}
      {/* <Features /> */}
      <Future />
      <Offer />
      <WhyAudeet />
      {/* <Aboutus />
      <Intro /> */}
      <Benifits />
      <FAQ />
      <Explore />
      <Footer />
    </div>
  );
};
