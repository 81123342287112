import React, { useState, useEffect } from 'react'
import './downloadinvoice.css'
import Sidenav from '../../components/Sidenav/Sidenav'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { DialogTitle } from '@mui/material';
// import { useNavigate } from 'react-router-dom/dist';
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceRequest } from "../../redux/actions/InvoiceAction";
import { SyncLoader } from "react-spinners";
import { useParams } from 'react-router-dom';

const DownloadInvoice = () => {

    // const navigate = useNavigate();
    const { id } = useParams();

    const [showShareModal, setShowShareModal] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const { InvoiceDetails, loading } = useSelector((state) => state.InvoiceReducer);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getInvoiceRequest(id));
    }, [dispatch, id]);

    const handleShare = () => {
        setShowShareModal(true);
    };

    const handleMore = () => {
        setShowMore(true);
    };

    // showing the pdf by convert the base64 string
    const handleUrl = (code) => {
        try {
            const byteCharacters = atob(code);
            const byteArrays = [];

            for (let i = 0; i < byteCharacters.length; i++) {
                byteArrays.push(byteCharacters.charCodeAt(i));
            }

            const byteArray = new Uint8Array(byteArrays);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            return URL.createObjectURL(blob);
        } catch (error) {
            console.error("Error decoding invoice code:", error);
            // return null;
        }
    };

    const handleDownloadPdf = () => {
        if (InvoiceDetails && InvoiceDetails?.invoiceCode) {
            const pdfUrl = handleUrl(InvoiceDetails?.invoiceCode);
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.download = 'invoice.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    const handleDuplicate = () => {
        if (InvoiceDetails && InvoiceDetails?.invoiceCode) {
            const pdfUrl = handleUrl(InvoiceDetails?.invoiceCode);
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.download = 'invoice.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    const handlePrint = () => {
        window.print();
    };

    // const handleWhatsAppShare = () => {
    //     const message = `Check out this invoice: ${handleUrl(InvoiceDetails?.invoiceCode)}`;
    //     window.open(`https://wa.me/?text=${encodeURIComponent(message)}`);
    // };

    // const handleEmailShare = () => {
    //     const subject = 'Invoice';
    //     const body = `Check out this invoice: ${handleUrl(InvoiceDetails?.invoiceCode)}`;
    //     window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    // };

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: "#F7F7F7"
        }}>
            <SyncLoader color="#011135" />
        </div>
    }

    return (
        <div className='downloadinvoice'>
            <div className="left-di">
                <Sidenav />
            </div>
            <div className='right-di'>
                <div className="ldowninvc-header">Sale Invoice</div>
                <div className='rbottom-di'>
                    <div className='bdowninvc-pdf'>
                        {/* <button>TAX INVOICE</button> */}
                        {InvoiceDetails && InvoiceDetails?.invoiceCode && (
                            <div className="invoice-pdf">
                                <embed
                                    src={handleUrl(InvoiceDetails?.invoiceCode)}
                                    type="application/pdf"
                                    width="90%"
                                    height="100%"
                                />
                            </div>
                        )}
                    </div>

                    <div className='br-di-cont'>
                        <div className='rdi-btn-cont' >
                            <button className='rdi-share-btn' onClick={handleShare}>
                                Share Invoice
                            </button>
                            <Dialog
                                open={showShareModal}
                                onClose={() => setShowShareModal(false)}
                                fullWidth={true} // Set to true to make the dialog take up the full width
                                maxWidth="xs"
                            >
                                <DialogTitle>
                                    Share invoice via
                                </DialogTitle>
                                <DialogActions style={{ justifyContent: 'center' }}>
                                    <div className='di-pop-share-btn'>
                                        <button>
                                            <img src="/assests/whatsapp.png" alt="" />
                                            Whatsapp(PDF)
                                        </button>
                                        <button>
                                            <img src="/assests/email.png" alt="" />
                                            Email
                                        </button>
                                        <button>
                                            <img src="/assests/other.png" alt="" />
                                            Other apps
                                        </button>
                                    </div>
                                </DialogActions>
                            </Dialog>

                            <button className='rdi-more-btn' onClick={handleMore} >
                                More option
                            </button>
                            <Dialog
                                open={showMore}
                                onClose={() => setShowMore(false)}
                                fullWidth={true} // Set to true to make the dialog take up the full width
                                maxWidth="xs"
                            >
                                <DialogTitle>
                                    More options
                                </DialogTitle>
                                <DialogActions style={{ justifyContent: 'center' }}>
                                    <div className='di-pop-share-btn'>
                                        <button onClick={handleDuplicate}>
                                            <img src="/assests/duplicate.png" alt="" />
                                            Dublicate
                                        </button>
                                        <button onClick={handlePrint}>
                                            <img src="/assests/print.png" alt="" />
                                            Print
                                        </button>
                                        <button onClick={handleDownloadPdf}>
                                            <img src="/assests/Download.png" alt="" />
                                            Download as pdf
                                        </button>
                                    </div>
                                </DialogActions>
                            </Dialog>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DownloadInvoice