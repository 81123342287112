import React, { useState, useEffect } from 'react'
import './invoicecard.css'
import { TfiSearch } from 'react-icons/tfi';
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { FaIndianRupeeSign } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom/dist';
import { useSelector } from "react-redux";
// import { base64StringToBlob } from 'blob-util';
// import { getDocument } from 'pdfjs-dist/webpack';

const Invoicecard = () => {

    const navigate = useNavigate();

    const { Invoice } = useSelector((state) => state.InvoiceReducer);

    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
    const [showPaymentInOut, setShowPaymentInOut] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(Invoice?.invoice[0]);
    // const [invoiceText, setInvoiceText] = useState('');

    // useEffect(() => {
    //     // Load PDFJS worker
    //     getDocument('').promise.then(() => {
    //         // Worker loaded successfully
    //         console.log('PDF.js worker loaded successfully');
    //     }).catch(error => {
    //         console.error('Error loading PDF.js worker:', error);
    //     });
    // }, []);

    const handleAddPayment = () => {
        setShowAddPaymentModal(true);
    };

    const handlePaymentInOut = () => {
        setShowAddPaymentModal(false);
        setShowPaymentInOut(true);
    };

    const handlePaymentInOutClose = () => {
        setShowPaymentInOut(false);
    };

    // create invoice
    const handleCreateInvoice = () => {
        navigate('/create-invoice')
    };

    // select the invoice card?
    const handleSelectInvoice = (invoice) => {
        setSelectedInvoice(invoice);
        // if (selectedInvoice) {
        //     // Convert base64 PDF to blob
        //     const pdfBlob =  base64StringToBlob(selectedInvoice.invoiceCode, 'application/pdf');
        //     // Read text data from PDF
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         const typedArray = new Uint8Array(reader.result);
        //         getDocument(typedArray)
        //             .promise.then((pdf) => {
        //                 let text = '';
        //                 const maxPages = pdf.numPages;
        //                 const promises = [];
        //                 for (let j = 1; j <= maxPages; j++) {
        //                     promises.push(pdf.getPage(j).then((page) => page.getTextContent()));
        //                 }
        //                 Promise.all(promises).then((content) => {
        //                     content.forEach((page) => {
        //                         page.items.forEach((item) => {
        //                             text += item.str + '\n';
        //                         });
        //                     });
        //                     setInvoiceText(text);
        //                     console.log('Invoice Text:', text);
        //                 });
        //             })
        //             .catch((error) => console.error('Error reading PDF:', error));
        //     };
        //     reader.readAsArrayBuffer(pdfBlob);
        // }
    };

    // view invoice
    const handleViewInvoice = () => {
        if (selectedInvoice) {
            navigate(`/download-invoice/${selectedInvoice._id}`);
        }
    };

    return (
        <div className='invoicecard'>
            <div className="invoicecard-cont">
                <div className="left-invcard">

                    <div className='left-invcard-cont'>

                        <div className="linvc-header">Sale Invoice</div>

                        <div className='licard-search-cont'>
                            <div className='licard-search-box'>
                                <span>
                                    <TfiSearch />
                                </span>
                                <input
                                    type='text'
                                    placeholder='Search'
                                // value={searchTerm}
                                // onChange={handleInputChange}
                                // onClick={handleSearchContainerClick}
                                // onFocus={handleSearchContainerClick}
                                />
                            </div>
                        </div>

                        <div className='linvoice-card-loop' >
                            {Invoice?.invoice?.map((invoice) => (
                                <div className='linvoice-card' key={invoice._id} onClick={() => handleSelectInvoice(invoice)}>
                                    <div className="leftinvoice-card">
                                        <div>
                                            <h5 className='left-invcard-logo'>A</h5>
                                            <button className='linvcard-edit-btn'>
                                                Edit <FaRegEdit />
                                            </button>
                                        </div>
                                        <div className='linvcard-lp-cont'>
                                            <h6>Arjun Srivastav</h6>
                                            <p>{new Date(invoice.createdAt).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                        </div>
                                    </div>
                                    <div className="left-right-inv-card">
                                        <div className='linvcard-text-cont'>
                                            <span>Invoice#1</span>
                                        </div>
                                        <div className='linvcard-price-cont'>
                                            <button><FaIndianRupeeSign /> 1000.00</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='ic-ci-btn-cont'>
                            <button onClick={handleCreateInvoice}>Create Invoice</button>
                        </div>
                    </div>
                </div>

                <div className="right-invc">
                    {/*  */}
                    <div className='rinvc-cont'>
                        <div className='top-rinvc' >
                            <div className='tr-invc-btn-cont'>
                                <button> <FaRegEdit />Edit Profile</button>
                                <button><AiOutlineDelete /> Delete</button>
                            </div>
                            <div className="tr-inv-detail-cont">
                                <div className='tleft-inv-det-cont'>
                                    <h5>A</h5>
                                    <div>
                                        <span>Arjun Srivastav</span>
                                        <p>{new Date(selectedInvoice?.createdAt).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                    </div>
                                </div>
                                <div className='trinv-det-cont'>
                                    <div>
                                        <span>Invoice no</span>
                                        <span>1</span>
                                    </div>
                                    <div>
                                        <span>Invoice Date</span>
                                        <span>4 th May 2023</span>
                                    </div>
                                    <div>
                                        <span>Due Date</span>
                                        <span>4 th May 2023</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* right second */}
                        <div className='invc-second-cont'>
                            <div className='linvc-second-cont'>
                                <h6>T</h6>
                                <div>
                                    <strong>T-Shirt</strong>
                                    <span>Product</span>
                                </div>
                            </div>
                            <div className='rinvc-second-cont'>
                                <span>₹ 419</span>
                            </div>
                        </div>

                        {/* price breakup */}
                        <div className='rinvc-price-bu-cont' >
                            <div className="rinvc-price-bu-header">
                                <h6>Price breakup</h6>
                            </div>
                            <div className="rinvc-pbu-bottom">
                                <table>
                                    <tr>
                                        <td>Sale Price </td>
                                        <td>₹ 499</td>
                                    </tr>
                                    <tr>
                                        <td>Discount (20%)</td>
                                        <td>₹ 99.00</td>
                                    </tr>
                                    <tr>
                                        <td>IGST @ 5%</td>
                                        <td>₹ 19.96</td>
                                    </tr>
                                    <tr className='rinvc-pbu-total'>
                                        <th>Total Selling Price</th>
                                        <th>₹ 419.16</th>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        {/* payment status */}
                        <div className='rinvc-pay-status-cont'>
                            <div className="left-rinvc-pay-status">
                                <h6>Status</h6>
                                <button>Paid</button>
                            </div>
                            <div className="right-rinvc-pay-status">
                                <div>
                                    <h6>You Got</h6>
                                    <span>₹499</span>
                                </div>
                                <div className='rinvc-pay-type-cont'>
                                    <h6>Payment type-</h6>
                                    <span>Cash</span>
                                </div>
                            </div>
                        </div>

                        {/* share container */}
                        <div className='rinv-share-cont' >
                            <div>
                                <img src="/assests/whatsapp.png" alt="" />
                                <span>Reminder</span>
                            </div>
                            <div>
                                <img src="/assests/sms.png" alt="" />
                                <span>SMS Reminder</span>
                            </div>
                            <div>
                                <img src="/assests/pay.png" alt="" />
                                <span>Pay</span>
                            </div>
                        </div>

                        <div className='rinvc-btn-cont' >
                            <button className='rinvc-viewinv-btn' onClick={handleViewInvoice}>
                                View Invoice
                            </button>
                            <button className='rinvc-addpay-btn' onClick={handleAddPayment} >
                                Add Payment
                            </button>
                            <Dialog
                                open={showAddPaymentModal}
                                onClose={() => setShowAddPaymentModal(false)}
                                fullWidth={true} // Set to true to make the dialog take up the full width
                                maxWidth="xs"
                            >
                                <div className='invc-addpay-popup-cont'>
                                    <button className='invc-popup-payin-btn' onClick={handlePaymentInOut}>Payment In</button>
                                    <button className='invc-popup-payout-btn' onClick={handlePaymentInOut}>Payment out</button>
                                </div>
                            </Dialog>

                            <Dialog
                                // fullWidth
                                // fullHeight
                                // maxWidth={maxWidth}
                                open={showPaymentInOut}
                                onClose={handlePaymentInOutClose}
                                fullWidth={true} // Set to true to make the dialog take up the full width
                                maxWidth="xs"
                            >
                                <DialogContent>
                                    <div className='cc-popup-toppay-cont'>
                                        <h4><FaIndianRupeeSign /></h4>
                                    </div>
                                    <div className='cc-popup-top-input-cont'>
                                        <input type="text" placeholder='Enter details' />
                                    </div>
                                    <div className='cc-popup-pm-cont'>
                                        <label htmlFor="paymentSwitch">
                                            Payment mode
                                            {/* <!-- Rounded switch --> */}
                                            <label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </label>
                                        <label>
                                            <select
                                            // value={selecteddate}
                                            // onChange={(e) => setSelecteddate(e.target.value)}
                                            >
                                                <option value="">Select Date</option>
                                                <option value="Intern ">20/05/2024</option>
                                            </select>
                                        </label>

                                    </div>
                                    <button className='cc-popup-save-btn' onClick={handlePaymentInOutClose}>Save</button>
                                    <DialogActions style={{ justifyContent: 'center' }}>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>


                        </div>
                    </div>
                </div>


            </div>
        </div >
    )
}

export default Invoicecard