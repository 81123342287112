import React, { useState } from 'react';
import './suppliercard.css'
import { TfiSearch } from 'react-icons/tfi';
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import CSheader from '../../components/CSheader/CSheader';
import { FaArrowUp, FaIndianRupeeSign } from 'react-icons/fa6';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GetAllSupplierRequest, UpdateSupplierStatusRequest } from '../../redux/actions/SupplierAction';

const Suppliercard = ({ handleAdd }) => {

    const { Suppliers } = useSelector((state) => state.SupplierReducer);

    const [open, setOpen] = useState(false);
    // const [openGotModal, setOpenGotModal] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState(Suppliers[0]);
    const [searchTerm, setSearchTerm] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [paymentType, setPaymentType] = useState('cash');

    const dispatch = useDispatch(); // Initialize useDispatch

    const handleGaveClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const filteredSuppliers = Suppliers.filter((supplier) => {
        // Perform case-insensitive search based on supplier name
        return supplier.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const handleSelectSupplier = (supplier) => {
        setSelectedSupplier(supplier);
    };

    const handlePaymentModeChange = () => {
        setPaymentType((prevType) => (prevType === 'cash' ? 'online' : 'cash'));
    };

    const handleUpdatePaymentStatus = () => {

        const supplierData = {
            supplierId: selectedSupplier._id,
            paymentDate: paymentDate,
            paymentType: paymentType,
            toBeCollected: !selectedSupplier.toBeCollected
        };

        dispatch(UpdateSupplierStatusRequest(supplierData));
        // Reset payment date and type after dispatching the action
        dispatch(GetAllSupplierRequest());
        setPaymentDate('');
        setPaymentType('cash');
    };

    return (
        <div className='suppliercard'>
            <div className="suppliercard-cont">

                <div className="left-sc">

                    <div className='left-sc-cont'>
                        <CSheader />
                        <div className='lsc-search-cont'>
                            <div className='lsc-search-box'>
                                <span>
                                    <TfiSearch />
                                </span>
                                <input
                                    type='text'
                                    placeholder='Search'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='lsup-card-loop' >
                            {/* {Suppliers.map((supplier) => ( */}
                            {filteredSuppliers.map((supplier) => (
                                <div className='lsup-card' key={supplier._id} onClick={() => handleSelectSupplier(supplier)}>
                                    <div className="leftsup-card">
                                        <div>
                                            <h5 className='left-sc-logo'>{supplier?.name?.split(' ')[0].charAt(0).toUpperCase()}</h5>
                                            <button className='lsc-edit-btn'>
                                                Edit <FaRegEdit />
                                            </button>
                                        </div>
                                        <div className='lsc-lp-cont'>
                                            <h6>{supplier.name}</h6>
                                            <p>{new Date(supplier.createdAt).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                        </div>
                                    </div>
                                    <div className="left-right-sc-card">
                                        <div className='lsc-supplier-text-cont'>
                                            <span>Supplier</span>
                                        </div>
                                        <div className='lsc-sup-price-cont'>
                                            <button style={{ color: supplier.toBeCollected ? '#009A34' : '#DD2121' }}> <FaIndianRupeeSign /> {supplier.amount} <FaArrowUp /></button>
                                        </div>
                                    </div>
                                </div>
                            ))
                            }
                        </div>

                        <div className='lsupc-add-supplier-btn'>
                            <button onClick={() => handleAdd()}>Add Supplier</button>
                        </div>
                    </div>

                </div>


                <div className="right-sc">
                    {/*  */}
                    <div className='rsc-cont'>
                        <div className='top-rsc' >
                            <div className="top-rsc-cont">
                                <div className='tleft-rsc-cont'>
                                    <h5>{selectedSupplier?.name?.split(' ')[0].charAt(0).toUpperCase()}</h5>
                                    <div>
                                        <span>{selectedSupplier.name}</span>
                                        <p>{new Date(selectedSupplier.createdAt).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                    </div>
                                </div>
                                <div className='tright-rsc-cont'>
                                    <button> <FaRegEdit />Edit Profile</button>
                                    <button><AiOutlineDelete /> Delete</button>
                                </div>
                            </div>

                            <div className='bottom-trsc-cont'>
                                <div>
                                    <h6>{selectedSupplier.toBeCollected ? 'You Got' : 'You Gave'}</h6>
                                    <span style={{ color: selectedSupplier.toBeCollected ? '#009A34' : '#DD2121' }}><FaIndianRupeeSign /> {selectedSupplier.amount}</span>
                                </div>
                            </div>

                        </div>

                        <div className='mid-rsc' >
                            <div>
                                <img src="/assests/pay.png" alt="" />
                                <span>Pay</span>
                            </div>
                        </div>

                        <div className='bottom-rsc' >
                            <div className='rsc-tran-cont'>
                                <div className="rsc-ttran-header">
                                    <h6>Transactions</h6>
                                </div>
                                <div className="rsc-tran-bottom">
                                    <div>
                                        <h6>Purchase Draft</h6>
                                        <span>{new Date(selectedSupplier.createdAt).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                    </div>
                                    <div>
                                        <p style={{ color: selectedSupplier.toBeCollected ? '#009A34' : '#DD2121' }}><FaIndianRupeeSign /> {selectedSupplier.amount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='rsc-btn-cont' >
                            <button className='rsc-gave-btn' onClick={handleGaveClick}>
                                You Gave
                            </button>
                            <button className='rsc-got-btn' onClick={handleGaveClick}>
                                You Got
                            </button>

                            <Dialog
                                open={open}
                                onClose={handleClose}
                                fullWidth={true} // Set to true to make the dialog take up the full width
                                maxWidth="xs"
                            >
                                <DialogContent>
                                    <div className='sc-popup-toppay-cont'>
                                        <h4><FaIndianRupeeSign /></h4>
                                    </div>
                                    <div className='sc-popup-top-input-cont'>
                                        <input type="text" placeholder='Enter details' />
                                    </div>
                                    <div className='sc-popup-pm-cont'>
                                        <label htmlFor="paymentSwitch" className='sc-popup-switch-label'>
                                            Payment mode
                                            <Stack direction="row" alignItems="center">
                                                <Typography>cash</Typography>
                                                <Switch
                                                    checked={paymentType === 'online'}
                                                    onChange={handlePaymentModeChange}
                                                    inputProps={{ 'aria-label': 'ant design' }}
                                                />
                                                <Typography>Online</Typography>
                                            </Stack>
                                        </label>
                                        <label className='sc-popup-date-label'>
                                            <input type='date' value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)} />
                                        </label>

                                    </div>
                                    <button className='sc-popup-save-btn' onClick={handleUpdatePaymentStatus}>Save</button>
                                    <DialogActions style={{ justifyContent: 'center' }}>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>


                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Suppliercard