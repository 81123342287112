import { axiosRequest } from '../../services/ApiCall.js';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export const SupplierApi = {
    addSupplierReq: async (payload) => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.post(`/supplier/add`, payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            if (response) {
                toast.success(response?.data?.message);
                return response.data;
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Add Supplier Failed! Please Try Again Later');
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    getSupplierReq: async () => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.get(`/supplier/:id`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error Fetching In Supplier', error);
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    getAllSupplierReq: async () => {
        try {
            const token = Cookies.get("audeet");
            const response = await axiosRequest.get(`/supplier/get-all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.suppliers;
        } catch (error) {
            console.error("Error Fetching In Customer", error);
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    updateSupplierStatus: async (payload) => {
        try {
            const token = Cookies.get("audeet");
            const response = await axiosRequest.put(`/supplier/update/status`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response) {
                toast.success(response?.data?.message);
                return response.data;
            }
        } catch (error) {
            // console.error('Edit company profile: Error', error);
            toast.error(error?.response?.data?.message || 'Update Payment Status! Please Try Again Later');
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
};
