export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_GET_REQUEST = "USER_GET_REQUEST";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAIL = "USER_GET_FAIL";

export const USER_EDIT_PROFILE_REQUEST = "USER_EDIT_PROFILE_REQUEST";
export const USER_EDIT_PROFILE_SUCCESS = "USER_EDIT_PROFILE_SUCCESS";
export const USER_EDIT_PROFILE_FAIL = "USER_EDIT_PROFILE_FAIL";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";