import React, { useState } from "react";
import "./Register.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import validator from "validator";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { UserRegisterRequest } from "../../redux/actions/AuthAction";
import { toast } from "react-toastify";

export const Register = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [passShow, setPassShow] = useState(false);
    const [passShow1, setPassShow1] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleRegister = (e) => {
        e.preventDefault();
        try {
            if (
                !email ||
                !phoneNo ||
                !password ||
                !confirmPassword ||
                !businessName
            ) {
                toast.error("All fields are required");
            } else if (phoneNo.length !== 10 || !/^[0-9]{10}$/.test(phoneNo)) {
                toast.error("Please enter a valid phone number");
            } else if (!validator.isMobilePhone(phoneNo, "en-IN")) {
                toast.error("Please enter a valid phone number");
            } else if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
                toast.error("Please enter a valid email");
            } else if (!validator.isEmail(email)) {
                toast.error("Please enter a valid email");
            } else if (password.length < 6) {
                toast.error("Length of password should be greater than 6 Charecter");
            } else if (password !== confirmPassword) {
                toast.error("confirm password and password not match");
            } else {
                const userData = {
                    name,
                    businessName,
                    email,
                    phoneNo,
                    password,
                };
                dispatch(UserRegisterRequest(userData, navigate));
            }
        } catch (error) {
            console.error('Error during registration:', error);
        }
    };

    return (
        <div className="register-container">
            <div className="left-part">
                <p>
                    "Audeet: Simplifying your accounting tasks with intuitive features,
                    seamless invoicing, and robust financial management tools."
                </p>
            </div>
            <div className="right-part ">
                <div className="register-container">
                    <form className="register-form">
                        <div className="form_input">
                            <label htmlFor="name">Name</label>
                            <input
                                name="name"
                                type="text"
                                placeholder="Enter Your Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="form_input">
                            <label htmlFor="email">Email</label>

                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                name="email"
                                id="email"
                                placeholder="Enter Your Email"
                            />
                        </div>
                        <div className="form_input">
                            <label htmlFor="phoneno">Phone no</label>
                            <input
                                type="tel"
                                placeholder="Enter Your Phone No"
                                value={phoneNo}
                                onChange={(e) => setPhoneNo(e.target.value)}
                            />
                        </div>
                        <div className="form_input">
                            <label htmlFor="businessName">Business Name</label>
                            <input
                                type="text"
                                name="businessName"
                                placeholder="Enter Your Business Name"
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                            />
                        </div>
                        <div className="form_input">
                            <label htmlFor="password">Password</label>
                            <div className="hnl-input-passicon-cont">
                                <input
                                    type={!passShow ? "password" : "text"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    name="password"
                                    id="password"
                                />
                                <div
                                    className="hn-showpass"
                                    onClick={() => setPassShow(!passShow)}
                                >
                                    {!passShow ? <VscEyeClosed /> : <VscEye />}
                                </div>
                            </div>
                        </div>

                        <div className="form_input">
                            <label htmlFor="confirm-password">Confirm Password</label>
                            <div className="hnl-input-passicon-cont">
                                <input
                                    type={!passShow1 ? "password" : "text"}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    name="confirm-password"
                                    id="confirm-password"
                                />
                                <div
                                    className="hn-showpass"
                                    onClick={() => setPassShow1(!passShow1)}
                                >
                                    {!passShow1 ? <VscEyeClosed /> : <VscEye />}
                                </div>
                            </div>
                        </div>
                        <button className="hnlogin-signin-btn" onClick={handleRegister}>
                            Register
                        </button>
                        <div className="login-link">
                            Already have an account? <Link to="/login">login</Link>
                        </div>
                        {/* <h2>Phone No</h2>
                        <input type="text" placeholder="Enter Phone Number"
                            onChange={(e) => setPhoneNumber(e.target.value)} />
                        <h2>Password</h2>
                        <input type="text" placeholder="Enter Phone Number"
                            onChange={(e) => setPhoneNumber(e.target.value)} />
                        <button type="submit">Continue</button> */}
                    </form>
                </div>
            </div>
        </div>
    );
};
