import {
    GET_ALL_INVOICE_FAIL,
    GET_ALL_INVOICE_REQUEST,
    GET_ALL_INVOICE_SUCCESS,
    GET_INVOICE_FAIL,
    GET_INVOICE_REQUEST,
    GET_INVOICE_SUCCESS,
    INVOICE_GENERATE_FAIL,
    INVOICE_GENERATE_REQUEST,
    INVOICE_GENERATE_SUCCESS
} from "../constants/InvoiceConstant";

const initialState = {
    Invoice: {},
    InvoiceDetails: {},
    GeneratedInvoice: {},
    loading: false,
    error: null
};

const InvoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case INVOICE_GENERATE_REQUEST:
        case GET_INVOICE_REQUEST:
        case GET_ALL_INVOICE_REQUEST:
            return {
                ...state,
                loading: true
            };

        case INVOICE_GENERATE_SUCCESS:
            return {
                ...state,
                GeneratedInvoice: action.data,
                error: null,
                loading: false
            };

        case GET_ALL_INVOICE_SUCCESS:
            return {
                ...state,
                Invoice: action.data,
                error: null,
                loading: false
            };

        case GET_INVOICE_SUCCESS:
            return {
                ...state,
                InvoiceDetails: action.data,
                error: null,
                loading: false
            };

        case INVOICE_GENERATE_FAIL:
            return {
                ...state,
                GeneratedInvoice: null,
                error: action.error,
                loading: false
            };

        case GET_ALL_INVOICE_FAIL:
            return {
                ...state,
                Invoice: null,
                error: action.error,
                loading: false
            };

        case GET_INVOICE_FAIL:
            return {
                ...state,
                InvoiceDetails: null,
                error: action.error,
                loading: false
            };

        default:
            return state;
    }
};

export default InvoiceReducer;
