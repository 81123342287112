export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";

export const GET_ALL_PRODUCT_REQUEST = "GET_ALL_PRODUCT_REQUEST";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_FAIL = "GET_ALL_PRODUCT_FAIL";

export const UPDATE_PRODUCT_STOCK_STATUS_REQUEST = "UPDATE_PRODUCT_STOCK_STATUS_REQUEST";
export const UPDATE_PRODUCT_STOCK_STATUS_SUCCESS = "UPDATE_PRODUCT_STOCK_STATUS_SUCCESS";
export const UPDATE_PRODUCT_STOCK_STATUS_FAIL = "UPDATE_PRODUCT_STOCK_STATUS_FAIL";