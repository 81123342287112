import { axiosRequest } from "../../services/ApiCall.js";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const InvoiceApi = {
    generateInvoice: async (payload) => {
        try {
            console.log('payload', payload);
            const token = Cookies.get("audeet");
            const response = await axiosRequest.post(
                "/invoice/create-invoice",
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response) {
                toast.success(response?.data?.message);
                return response.data;
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                "Create Invoice Failed! Please Try Again Later"
            );
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    getInvoice: async (id) => {
        try {
            const token = Cookies.get("audeet");
            const response = await axiosRequest.get(`/invoice/get/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.invoice;
        } catch (error) {
            console.error("Error Fetching In Supplier", error);
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    getAllInvoice: async () => {
        try {
            const token = Cookies.get("audeet");
            const response = await axiosRequest.get(`/invoice/get-all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error Fetching In Invoice", error);
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
};
