import {
    ADD_SERVICE_FAIL,
    ADD_SERVICE_REQUEST,
    ADD_SERVICE_SUCCESS,
    GET_ALL_SERVICE_FAIL,
    GET_ALL_SERVICE_REQUEST,
    GET_ALL_SERVICE_SUCCESS,
    GET_SERVICE_FAIL,
    GET_SERVICE_REQUEST,
    GET_SERVICE_SUCCESS
} from '../constants/ServiceConstant';

export const AddServiceRequest = (serviceData) => ({
    type: ADD_SERVICE_REQUEST,
    payload: { serviceData }
});

export const AddServiceSuccess = (data) => ({
    type: ADD_SERVICE_SUCCESS,
    data,
});

export const AddServiceFailure = (error) => ({
    type: ADD_SERVICE_FAIL,
    error,
});

export const GetAllServiceRequest = (payload) => ({
    type: GET_ALL_SERVICE_REQUEST,
    payload,
});

export const GetAllServiceSuccess = (data) => ({
    type: GET_ALL_SERVICE_SUCCESS,
    data,
});

export const GetAllServiceFailure = (error) => ({
    type: GET_ALL_SERVICE_FAIL,
    error,
});

export const GetServiceRequest = (payload) => ({
    type: GET_SERVICE_REQUEST,
    payload,
});

export const GetServiceSuccess = (data) => ({
    type: GET_SERVICE_SUCCESS,
    data,
});

export const GetServiceFailure = (error) => ({
    type: GET_SERVICE_FAIL,
    error,
});
