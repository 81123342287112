import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.scss";
import { FaBars } from "react-icons/fa6";
import Modal from 'react-modal';
import { RxCross2 } from "react-icons/rx";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";

Modal.setAppElement('#root');

export const Navbar = () => {

  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);

  const { isUserAuth } = useSelector((state) => state.UserAuthReducer);

  const handleFabarClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFAQClick = () => {
    // Scroll to the campaigns component on the home page
    const campaignsComponent = document.getElementById('FAQ');
    if (campaignsComponent) {
      setModalOpen(false)
      campaignsComponent.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate('/');
    }
  };

  return (
    <div className="navbar">
      <div className="left">
        <img src="./assests/logo.png" alt="" onClick={() => navigate("/")} />
      </div>
      <div className="right">
        <div className="nav-opt" onClick={() => navigate("/")}>Home</div>
        <div className="nav-opt">Features</div>
        {/* <div className="nav-opt">About us</div> */}
        <div className="nav-opt" onClick={handleFAQClick}>FAQ</div>
        {/* <div className="nav-opt">Sign up</div> */}
        {/* <button className="nav-btn" onClick={() => navigate('/login')}>Login</button> */}
        {isUserAuth ? (
          <div className="nav-opt">
            <Link to="/profile">
              <Avatar src="/broken-image.jpg" />
            </Link>
          </div>
        ) : (
          <button className="nav-btn" onClick={() => navigate("/login")}>
            Login
          </button>
        )}
        <button onClick={handleFabarClick} className="nav-fabar"><FaBars /></button>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          // contentLabel="Example Modal"
          // className="custom-modal" 
          // overlayClassName="custom-overlay" 
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              // backgroundImage: `url('know more comp.png')`,
              // backgroundSize: 'cover',
              // backgroundRepeat: 'no-repeat',
              // width:'100px',
              // height: "150px",
              borderRadius: "20px",
              boxShadow: "0 4px 16px 0 rgb(0 0 0/20%)",
              backgroundPosition: 'center',
              marginTop: "100px"
            }
          }}
        >
          <div className="modal-content">
            <div className="nav-btn-cont-mob">
              <Link to="/" className='nav-link'>
                <button className='nav-btn-mob'>Home</button>
              </Link>
              <Link to="/" className='nav-link'>
                <button className='nav-btn-mob'>Features</button>
              </Link>
              <Link to="/" className='nav-link'>
                <button className='nav-btn-mob'>FAQ</button>
              </Link>
              {/* <Link to="/" className='nav-link'>
                <button className='nav-btn-mob'>Signup</button>
              </Link> */}
              {/* <Link to="/login" className='nav-link'>
                <button className='nav-btn-mob' onClick={() => navigate('/login')}>Login</button>
              </Link> */}
              {isUserAuth ? (
                <button className="nav-btn-mob" onClick={() => navigate("/profile")}>
                  Profile
                </button>
              ) : (
                <button className="nav-btn-mob" onClick={() => navigate("/login")}>
                  Login
                </button>
              )}
            </div>
            <button className="close-btn" onClick={handleCloseModal}> <RxCross2 /></button>
          </div>
        </Modal>

      </div>
    </div>
  );
};
