import React, { useEffect, useState } from 'react'
import './editprofile.css'
import Sidenav from '../../components/Sidenav/Sidenav'
import { useNavigate } from 'react-router-dom/dist'
import { SyncLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import { UserEditProfileRequest } from "../../redux/actions/AuthAction";
import validator from "validator";
import { toast } from "react-toastify";

const Editprofile = () => {

    const [activeTab, setActiveTab] = useState('Business');
    const [name, setName] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [gst, setGst] = useState("");
    const [state, setState] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");

    const [bankName, setBankName] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [branch, setBranch] = useState("");
    const [upiId, setUpiId] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, loading } = useSelector((state) => state.UserAuthReducer);

    useEffect(() => {
        if (user) {
            setName(user.name);
            setEmail(user.email);
            setBusinessName(user.businessName);
            setPhoneNo(user.phoneNo);
            setGst(user.gst || "");
            setState(user.state || "");
            setAddress1(user.address1 || "");
            setAddress2(user.address2 || "");
            setBankName(user.bankName || "");
            setAccountNo(user.accountNo || "");
            setIfsc(user.ifsc || "");
            setBranch(user.branch || "");
            setUpiId(user.upiId || "");
        }
    }, [user])

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleSaveChanges = (e) => {
        e.preventDefault();
        try {
            let userData = {
                name, businessName, phoneNo, email, gst, state, address1, address2, bankName, ifsc, branch, upiId, accountNo, website
            }
            if (!businessName.trim()) {
                toast.error("Business name is required");
            } else if (phoneNo.length !== 10 || !/^[0-9]{10}$/.test(phoneNo)) {
                toast.error("Please enter a valid phone number");
            } else if (!validator.isMobilePhone(phoneNo, "en-IN")) {
                toast.error("Please enter a valid phone number");
            } else if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
                toast.error("Please enter a valid email");
            } else if (!validator.isEmail(email)) {
                toast.error("Please enter a valid email");
            } else if (gst && !gst.match(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/)) {
                toast.error("Please enter a valid GST number");
            } else if (accountNo && !validator.isNumeric(accountNo)) {
                toast.error("Please enter a valid account number");
            } else if (bankName && !validator.isAlpha(bankName)) {
                toast.error("Please enter a valid bank name");
            } else if (branch && !validator.isAlpha(branch)) {
                toast.error("Please enter a valid branch name");
            } else if (upiId && !validator.isAlphanumeric(upiId)) {
                toast.error("Please enter a valid UPI ID");
            } else if (website && !validator.isURL(website)) {
                toast.error("Please enter a valid website URL");
            } else {
                dispatch(UserEditProfileRequest(userData, navigate))
            }
        } catch (error) {
            console.log(error);
        }
    };


    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: "#F7F7F7"
        }}>
            <SyncLoader color="#011135" />
        </div>
    }

    return (
        <div className="editprofile">
            <div className="editprofile-cont">
                <div className="left-ep">
                    <Sidenav />
                </div>

                <div className="right-ep">
                    <div className="ep-header">
                        <h3>
                            Profile Setup
                        </h3>
                    </div>

                    <div className="bottom-rep">
                        <div className="rleft-ep">
                            <div className="rleft-ep-card">

                                <button
                                    className={`tab-button ${activeTab === 'Business' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Business')}
                                >
                                    Business Information
                                </button>
                                <button
                                    className={`tab-button ${activeTab === 'Bank' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Bank')}
                                >
                                    Bank Details
                                </button>
                            </div>
                        </div>
                        <div className="rright-ep">
                            {activeTab === 'Business' && (
                                <div className="rright-ep-card">
                                    <div className="rright-ep-top-btn-cont">
                                        <h3>Business Information</h3>
                                        <button onClick={handleSaveChanges}>Save Changes</button>
                                    </div>

                                    <div className='rmform-two'>
                                        <label >Your name
                                            <input type='text' name='name' placeholder='Your name' value={name} onChange={(e) => setName(e.target.value)} ></input>
                                        </label>

                                        <label className='uempId'>Business name
                                            <input type='text' name='Business name' placeholder='Write your business name' value={businessName} onChange={(e) => setBusinessName(e.target.value)} ></input>
                                        </label>
                                    </div>

                                    <div className='rmform-two'>
                                        <label className='uempPosition'>Phone no.
                                            <input type='tel' name='phoneno' placeholder='Enter Your Phone No' value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} ></input>
                                        </label>
                                        <label >Email
                                            <input type='email' name='email' placeholder='Enter Your Email' value={email} onChange={(e) => setEmail(e.target.value)} ></input>
                                        </label>
                                    </div>

                                    <div className="rmform-single">
                                        <label className='uemp'>Business Website link</label>
                                        <input type='text' name='website' value={website} onChange={(e) => setWebsite(e.target.value)} ></input>
                                    </div>

                                    <div className="rright-ep-top-btn-cont">
                                        <h3>More Information</h3>
                                    </div>

                                    <div className='rmform-two'>
                                        <label >GSTIN
                                            <input type='text' name='gst' placeholder='Enter Your GSTIN' value={gst} onChange={(e) => setGst(e.target.value)} ></input>
                                        </label>

                                        <label className='uempId'>State
                                            <input type='text' name='text' placeholder='Enter Your State' value={state} onChange={(e) => setState(e.target.value)} ></input>
                                        </label>
                                    </div>

                                    <div className='rmform-two'>
                                        <label className='uempPosition'>Address line 1
                                            <input type='text' name='position' placeholder='' value={address1} onChange={(e) => setAddress1(e.target.value)} ></input>
                                        </label>
                                        <label className='uempJoin'>Address line 2
                                            <input type='text' name='Date' value={address2} onChange={(e) => setAddress2(e.target.value)} ></input>
                                        </label>
                                    </div>


                                </div>
                            )}

                            {activeTab === 'Bank' && (
                                <div className="rright-ep-card">
                                    <div className="rright-ep-top-btn-cont">
                                        <h3>Bank Deatils</h3>
                                    </div>

                                    <div className="rmform-single">
                                        <label >Bank name
                                        </label>
                                        <input type='text' name='bank name' placeholder='Enter Your Bank Name' value={bankName} onChange={(e) => setBankName(e.target.value)} ></input>
                                    </div>
                                    <div className="rmform-single">
                                        <label className='uempId'>Account no
                                        </label>
                                        <input type='text' name='account no' value={accountNo} onChange={(e) => setAccountNo(e.target.value)} ></input>
                                    </div>

                                    <div className='rmform-two'>
                                        <label className='uempPosition'>Branch
                                            <input type='text' name='branch' value={branch} onChange={(e) => setBranch(e.target.value)} ></input>
                                        </label>
                                        <label >IFSC
                                            <input type='text' name='ifsc' value={ifsc} onChange={(e) => setIfsc(e.target.value)} ></input>
                                        </label>
                                    </div>

                                    <div className="rmform-single">
                                        <label className='uemp'>UPI ID (optional)</label>
                                        <input type='text' name='upiid' value={upiId} onChange={(e) => setUpiId(e.target.value)} ></input>
                                    </div>


                                    <div className="rright-ep-top-btn-cont">
                                        <button onClick={handleSaveChanges}>Save Changes</button>
                                    </div>

                                </div>
                            )}

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Editprofile