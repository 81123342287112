import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_GET_REQUEST,
    USER_GET_SUCCESS,
    USER_GET_FAIL,
    USER_EDIT_PROFILE_REQUEST,
    USER_EDIT_PROFILE_FAIL,
    USER_EDIT_PROFILE_SUCCESS,
    USER_LOGOUT_SUCCESS,
    USER_LOGOUT_FAIL,
    USER_LOGOUT_REQUEST,
    CLEAR_ERRORS,
} from '../constants/AuthConstant';

const initialState = {
    user: {},
    loading: false,
    error: null
};

const UserAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
        case USER_LOGIN_REQUEST:
        case USER_GET_REQUEST:
        case USER_EDIT_PROFILE_REQUEST:
        case USER_LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
                isUserAuth: false,
            };

        case USER_REGISTER_SUCCESS:
        case USER_LOGIN_SUCCESS:
        case USER_GET_SUCCESS:
        case USER_EDIT_PROFILE_SUCCESS:
            return {
                ...state,
                user: action.data,
                error: null,
                loading: false,
                isUserAuth: true,
            };
        case USER_REGISTER_FAIL:
        case USER_LOGIN_FAIL:
        case USER_GET_FAIL:
        case USER_EDIT_PROFILE_FAIL:
        case USER_LOGOUT_FAIL:
            return {
                ...state,
                user: null,
                error: action.error,
                loading: false,
                isUserAuth: false,
            };

        case USER_LOGOUT_SUCCESS:
            return {
                ...state,
                user: null,
                error: null,
                loading: false,
                isUserAuth: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export default UserAuthReducer;
