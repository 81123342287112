import {
    GET_ALL_INVOICE_FAIL,
    GET_ALL_INVOICE_REQUEST,
    GET_ALL_INVOICE_SUCCESS,
    GET_INVOICE_FAIL,
    GET_INVOICE_REQUEST,
    GET_INVOICE_SUCCESS,
    INVOICE_GENERATE_FAIL,
    INVOICE_GENERATE_REQUEST,
    INVOICE_GENERATE_SUCCESS,
} from "../constants/InvoiceConstant";

// export const generateInvoiceRequest = ({ invoiceData }) => ({
//     type: INVOICE_GENERATE_REQUEST,
//     payload: { invoiceData },
// });

// export const generateInvoiceSuccess = (data) => ({
//     type: INVOICE_GENERATE_SUCCESS,
//     data,
// });

// export const generateInvoiceFailure = (error) => ({
//     type: INVOICE_GENERATE_FAIL,
//     error,
// });
export const generateInvoiceRequest = (invoiceData) => {
    console.log("Generating invoice request:", invoiceData);
    return {
        type: INVOICE_GENERATE_REQUEST,
        payload: { invoiceData },
    };
};

export const generateInvoiceSuccess = (data) => {
    console.log("Invoice generated successfully:", data);
    return {
        type: INVOICE_GENERATE_SUCCESS,
        data,
    };
};

export const generateInvoiceFailure = (error) => {
    console.error("Error generating invoice:", error);
    return {
        type: INVOICE_GENERATE_FAIL,
        error,
    };
};

export const getInvoiceRequest = (id) => ({
    type: GET_INVOICE_REQUEST,
    payload: { id },
});

export const getInvoiceSuccess = (data) => ({
    type: GET_INVOICE_SUCCESS,
    data,
});

export const getInvoiceFailure = (error) => ({
    type: GET_INVOICE_FAIL,
    error,
});

export const GetAllInvoiceRequest = () => ({
    type: GET_ALL_INVOICE_REQUEST,
});

export const GetAllInvoiceSuccess = (data) => ({
    type: GET_ALL_INVOICE_SUCCESS,
    data,
});

export const GetAllInvoiceFailure = (error) => ({
    type: GET_ALL_INVOICE_FAIL,
    error,
});