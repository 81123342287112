import { all, takeLatest, call, put } from 'redux-saga/effects';
import { SupplierApi } from '../api/SupplierApi';
import {
    ADD_SUPPLIER_FAIL,
    ADD_SUPPLIER_REQUEST,
    ADD_SUPPLIER_SUCCESS,
    GET_ALL_SUPPLIER_FAIL,
    GET_ALL_SUPPLIER_REQUEST,
    GET_ALL_SUPPLIER_SUCCESS,
    GET_SUPPLIER_FAIL,
    GET_SUPPLIER_REQUEST,
    GET_SUPPLIER_SUCCESS,
    UPDATE_SUPPLIER_STATUS_FAIL,
    UPDATE_SUPPLIER_STATUS_REQUEST,
    UPDATE_SUPPLIER_STATUS_SUCCESS
} from '../constants/SupplierConstant';

function* AddSupplierSagaWorker(action) {
    try {
        const { supplierData } = action.payload;
        const result = yield call(SupplierApi.addSupplierReq, supplierData);
        yield put({ type: ADD_SUPPLIER_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: ADD_SUPPLIER_FAIL, error });
    }
}

function* GetSupplierSagaWorker(action) {
    try {
        const result = yield call(SupplierApi.getSupplierReq, action.payload);
        yield put({ type: GET_SUPPLIER_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: GET_SUPPLIER_FAIL, error });
    }
}

function* GetAllSupplierSagaWorker(action) {
    try {
        const result = yield call(SupplierApi.getAllSupplierReq);
        yield put({ type: GET_ALL_SUPPLIER_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: GET_ALL_SUPPLIER_FAIL, error });
    }
}

function* UpdateSupplierStatusSagaWorker(action) {
    try {
        const { supplierData } = action.payload;
        const result = yield call(SupplierApi.updateSupplierStatus, supplierData);
        yield put({ type: UPDATE_SUPPLIER_STATUS_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: UPDATE_SUPPLIER_STATUS_FAIL, error });
    }
}

function* SupplierSagaWatcher() {
    yield takeLatest(ADD_SUPPLIER_REQUEST, AddSupplierSagaWorker);
    yield takeLatest(GET_SUPPLIER_REQUEST, GetSupplierSagaWorker);
    yield takeLatest(GET_ALL_SUPPLIER_REQUEST, GetAllSupplierSagaWorker);
    yield takeLatest(UPDATE_SUPPLIER_STATUS_REQUEST, UpdateSupplierStatusSagaWorker);
}

export default function* SupplierSaga() {
    yield all([SupplierSagaWatcher()]);
}

