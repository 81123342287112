export const ADD_SERVICE_REQUEST = "ADD_SERVICE_REQUEST";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_FAIL = "ADD_SERVICE_FAIL";

export const GET_SERVICE_REQUEST = "GET_SERVICE_REQUEST";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_FAIL = "GET_SERVICE_FAIL";

export const GET_ALL_SERVICE_REQUEST = "GET_ALL_SERVICE_REQUEST";
export const GET_ALL_SERVICE_SUCCESS = "GET_ALL_SERVICE_SUCCESS";
export const GET_ALL_SERVICE_FAIL = "GET_ALL_SERVICE_FAIL";