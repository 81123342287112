import React, { useState } from 'react'
import './saleproduct.css'
import Productheader from '../../components/ProductHeader/Productheader'
// import Sidenav from '../../components/Sidenav/Sidenav'
import { TfiSearch } from 'react-icons/tfi';
import { FaRegEdit } from "react-icons/fa";
// import { SlShareAlt } from "react-icons/sl";
// import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { GetAllProductRequest, UpdateProductStatusRequest } from '../../redux/actions/ProductAction';

const Saleproduct = ({ handleAddProduct }) => {

    const { Product } = useSelector((state) => state.ProductReducer);

    const [openStockInModal, setOpenStockInModal] = useState(false);
    const [openStockOutModal, setOpenStockOutModal] = useState(false);
    const [openingStockModal, setOpeningStockModal] = useState(false);
    const [stockOutDate, setStockOutDate] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [openingStock, setOpeningStock] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(Product[0]);

    const dispatch = useDispatch(); // Initialize useDispatch

    const filteredProducts = Product.filter((product) => {
        // Perform case-insensitive search based on product name
        return product.itemName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const handleSelectProduct = (product) => {
        setSelectedProduct(product);
    };

    const handleUpdateProductStockStatus = () => {

        const productData = {
            productId: selectedProduct._id,
            // stockOutDate: stockOutDate,
            // salePrice: salePrice,
            // openingStock: openingStock,
            stockOutDate: stockOutDate !== '' ? stockOutDate : selectedProduct.stockOutDate,
            salePrice: salePrice !== '' ? salePrice : selectedProduct.salePrice,
            openingStock: openingStock !== '' ? openingStock : selectedProduct.openingStock,
        };

        dispatch(UpdateProductStatusRequest(productData));
        // Reset payment date and type after dispatching the action
        dispatch(GetAllProductRequest());
    };

    return (
        <div className='saleproduct'>
            <div className="saleproduct-cont">
                <div className="left-sp">
                    {/* <Sidenav /> */}
                </div>

                <div className="right-sp">
                    {/*  */}
                    <div className='rsp-left-cont'>
                        <Productheader />
                        <div className='rsp-search-cont'>
                            <div className='rsp-search-box'>
                                <span>
                                    <TfiSearch />
                                </span>
                                <input
                                    type='text'
                                    placeholder='Search'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='rsp-prod-card-loop' >
                            {/* {Product?.map((product) => ( */}
                            {filteredProducts.map((product) => (
                                <div className='rsp-prod-card' key={product._id} onClick={() => handleSelectProduct(product)}>
                                    <div className="rsp-left-prod-card">
                                        <div>
                                            <h5 className='rsp-left-pro-logo'>{product?.itemName?.split(' ')[0].charAt(0).toUpperCase()}</h5>
                                            <button className='rsp-edit-btn'>
                                                Edit <FaRegEdit />
                                            </button>
                                        </div>
                                        <div className='rsp-lp-cont'>
                                            <h6>{product.itemName}</h6>
                                            <p>{new Date(product.createdAt).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}<span>Low stock</span></p>
                                        </div>
                                    </div>
                                    <div className="rsp-right-prod-card">
                                        <div className='rsp-rp-pro-text-cont'>
                                            <span>Product</span>
                                        </div>
                                        <div className='rsp-rp-in-out-cont'>
                                            <button>+ IN</button>
                                            <button>+ OUT</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className='rspc-add-product-btn'>
                            <button onClick={() => handleAddProduct()}>Add Product</button>
                        </div>
                    </div>

                    <div className='rsp-right-cont'>
                        <div className='top-rsp-rp' >
                            <div className="top-rsp-prod-cont">
                                <div className='tleft-rsp-prod-cont'>
                                    <h5>{selectedProduct?.itemName?.split(' ')[0].charAt(0).toUpperCase()}</h5> <span>{selectedProduct.itemName}</span>
                                </div>
                                <div className='tright-rsp-prod-cont'>
                                    {/* <button> <SlShareAlt />Share</button> */}
                                    <button> <FaRegEdit />Edit Item</button>
                                    {/* <button><AiOutlineDelete /> Delete</button> */}
                                </div>
                            </div>
                        </div>

                        <div className='mid-rsp-rp' >
                            <div>
                                <span>Sale Price</span>
                                <strong>{selectedProduct?.salePrice}</strong>
                            </div>
                            <div>
                                <span>Stock Quantity</span>
                                <strong>{selectedProduct?.unit}</strong>
                            </div>
                            <div>
                                <span>Purchase Price</span>
                                <strong>{selectedProduct?.purchasePrice}</strong>
                            </div>
                            <div>
                                <span>Stock Value</span>
                                <strong>{selectedProduct?.salePrice * selectedProduct?.unit}</strong>
                            </div>
                        </div>

                        <div className='bottom-rsp-rp' >
                            <div className='rsp-timeline-cont'>
                                <div className="rsp-timeline-header">
                                    <h6>Timeline</h6>
                                </div>
                                <div className="rsp-timeline-bottom" >
                                    <div>
                                        <h6 onClick={() => setOpeningStockModal(true)}>Opening Stock +</h6>
                                        <span>{new Date(selectedProduct?.stockOutDate).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                    </div>
                                    <div>
                                        <p>{selectedProduct.openingStock ? selectedProduct.openingStock : "-"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='rsp-rp-btn-cont' >
                            <button className='rsp-stockin-btn' onClick={() => setOpenStockInModal(true)}>
                                Stock in
                            </button>
                            <Dialog
                                open={openStockInModal}
                                onClose={() => setOpenStockInModal(false)}
                                fullWidth={true} // Set to true to make the dialog take up the full width
                                maxWidth="xs"
                            >
                                <DialogContent>
                                    <div className='spcm-heading'>
                                        <h3>Stock In</h3>
                                        <h6>Enter the quantity of added items</h6>
                                    </div>
                                    <div className='spc-modal-cont'>
                                        <label>
                                            Sale price
                                            <input type="text" value={salePrice} onChange={(e) => setSalePrice(e.target.value)} />
                                        </label>
                                        <label>
                                            Stock out date
                                            <input type='date' value={stockOutDate} onChange={(e) => setStockOutDate(e.target.value)} />
                                        </label>
                                    </div>
                                    <button className='spc-modal-stock-in-btn' onClick={handleUpdateProductStockStatus}>STOCK IN</button>
                                </DialogContent>
                            </Dialog>

                            <button className='rsp-stockout-btn' onClick={() => setOpenStockOutModal(true)}>
                                Stock out
                            </button>
                            <Dialog
                                open={openStockOutModal}
                                onClose={() => setOpenStockOutModal(false)}
                                fullWidth={true} // Set to true to make the dialog take up the full width
                                maxWidth="xs"
                            >
                                <DialogContent>
                                    <div className='spcm-heading'>
                                        <h3>Stock In</h3>
                                        <h6>Enter the quantity of sold items</h6>
                                    </div>
                                    <div className='spc-modal-cont'>
                                        <label>
                                            Sale price
                                            <input type="text" value={salePrice} onChange={(e) => setSalePrice(e.target.value)} />
                                        </label>
                                        <label>
                                            Stock out date
                                            <input type='date' value={stockOutDate} onChange={(e) => setStockOutDate(e.target.value)} />
                                        </label>
                                    </div>
                                    <button className='spc-modal-stock-out-btn' onClick={handleUpdateProductStockStatus}>STOCK OUT</button>
                                </DialogContent>
                            </Dialog>
                        </div>
                    </div>
                </div>


            </div>
            {/* for update the opening stock value */}
            <Dialog
                open={openingStockModal}
                onClose={() => setOpeningStockModal(false)}
                fullWidth={true} // Set to true to make the dialog take up the full width
                maxWidth="xs"
            >
                <DialogContent>
                    <div className='spcm-heading'>
                        <h3>Opening Stock</h3>
                    </div>
                    <div className='spc-modal-cont'>
                        <label>
                            Opening Stock
                            <input type="text" value={openingStock} onChange={(e) => setOpeningStock(e.target.value)} />
                        </label>
                        <label>
                            Stock out date
                            <input type='date' value={stockOutDate} onChange={(e) => setStockOutDate(e.target.value)} />
                        </label>
                    </div>
                    <button className='spc-modal-save-btn' onClick={handleUpdateProductStockStatus}>SAVE</button>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Saleproduct