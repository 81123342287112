import React from 'react'
import "./explore.css"

const Explore = () => {
    return (
        <div className='explore'>
            <div className="explore-cont">
                <div className="topexplore">
                    <div className="ltopexplore">
                        <h3>"Unlock the power of seamless account management with <span>AUDEET</span>”</h3>
                        <h5>Innovate, Transform, and Excel your business with AI and Blockchain.</h5>
                    </div>
                    <div className="rtopexplore-btn-cont">
                        <button className='ltexp-signup-btn'>SignUp</button>
                        <button className='ltexp-login-btn'>Login</button>
                    </div>
                </div>
            </div>

            <div className="bottom-explore">
                <div className="bottom-exp-cont">
                    <div className='bec-img'>
                        <img src="/assests/Group 303.png" alt="" />
                    </div>
                    <div className='bec-text'>
                        <h3>Reach out to us today and simplify data input, streamlining processes, and <span>empowering your business to thrive.</span>"</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Explore