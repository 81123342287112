import { axiosRequest } from '../../services/ApiCall.js';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export const ServiceApi = {
    addServiceReq: async (payload) => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.post(`/service/add-service`, payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            if (response) {
                toast.success(response?.data?.message);
                return response.data;
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Add Service Failed! Please Try Again Later');
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    getAllServiceReq: async () => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.get(`/service/get-all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.service;
        } catch (error) {
            console.error('Error Fetching In Service', error);
            throw error; // Rethrow the error to be caught in the calling function
        }
    },
    getServiceReq: async () => {
        try {
            const token = Cookies.get('audeet');
            const response = await axiosRequest.get(`/service/:id`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error Fetching In Service', error);
            throw error; // Rethrow the error to be caught in the calling function
        }
    },

};
