import { all, takeLatest, call, put } from 'redux-saga/effects';
import { ServiceApi } from '../api/ServiceApi';
import {
    ADD_SERVICE_FAIL,
    ADD_SERVICE_REQUEST,
    ADD_SERVICE_SUCCESS,
    GET_ALL_SERVICE_FAIL,
    GET_ALL_SERVICE_REQUEST,
    GET_ALL_SERVICE_SUCCESS,
    GET_SERVICE_FAIL,
    GET_SERVICE_REQUEST,
    GET_SERVICE_SUCCESS
} from '../constants/ServiceConstant';

function* AddServiceSagaWorker(action) {
    try {
        const { serviceData } = action.payload;
        const result = yield call(ServiceApi.addServiceReq, serviceData);
        yield put({ type: ADD_SERVICE_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: ADD_SERVICE_FAIL, error });
    }
}

function* GetAllServiceSagaWorker(action) {
    try {
        const result = yield call(ServiceApi.getAllServiceReq, action.payload);
        yield put({ type: GET_ALL_SERVICE_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: GET_ALL_SERVICE_FAIL, error });
    }
}

function* GetServiceSagaWorker(action) {
    try {
        const result = yield call(ServiceApi.getServiceReq, action.payload);
        yield put({ type: GET_SERVICE_SUCCESS, data: result });
    } catch (error) {
        yield put({ type: GET_SERVICE_FAIL, error });
    }
}

function* ServiceSagaWatcher() {
    yield takeLatest(ADD_SERVICE_REQUEST, AddServiceSagaWorker);
    yield takeLatest(GET_ALL_SERVICE_REQUEST, GetAllServiceSagaWorker);
    yield takeLatest(GET_SERVICE_REQUEST, GetServiceSagaWorker);
}

export default function* ServiceSaga() {
    yield all([ServiceSagaWatcher()]);
}

