export const ADD_SUPPLIER_REQUEST = "ADD_SUPPLIER_REQUEST";
export const ADD_SUPPLIER_SUCCESS = "ADD_SUPPLIER_SUCCESS";
export const ADD_SUPPLIER_FAIL = "ADD_SUPPLIER_FAIL";

export const GET_SUPPLIER_REQUEST = "GET_SUPPLIER_REQUEST";
export const GET_SUPPLIER_SUCCESS = "GET_SUPPLIER_SUCCESS";
export const GET_SUPPLIER_FAIL = "GET_SUPPLIER_FAIL";

export const GET_ALL_SUPPLIER_REQUEST = "GET_ALL_SUPPLIER_REQUEST";
export const GET_ALL_SUPPLIER_SUCCESS = "GET_ALL_SUPPLIER_FAIL  ";
export const GET_ALL_SUPPLIER_FAIL = "GET_ALL_SUPPLIER_FAIL ";

export const UPDATE_SUPPLIER_STATUS_REQUEST = "UPDATE_SUPPLIER_STATUS_REQUEST";
export const UPDATE_SUPPLIER_STATUS_SUCCESS = "UPDATE_SUPPLIER_STATUS_SUCCESS";
export const UPDATE_SUPPLIER_STATUS_FAIL = "UPDATE_SUPPLIER_STATUS_FAIL";