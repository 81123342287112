import React, { useState, useEffect } from 'react';
import './createinvoice.css'
import Sidenav from '../../components/Sidenav/Sidenav'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { TfiSearch } from 'react-icons/tfi';
import { useNavigate } from "react-router-dom";
import { generateInvoiceRequest } from '../../redux/actions/InvoiceAction';
import { useDispatch, useSelector } from "react-redux";
import { SyncLoader } from "react-spinners";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { GetAllCustomerRequest } from '../../redux/actions/CustomerAction';
import { GetAllProductRequest } from '../../redux/actions/ProductAction';

const Createinvoice = () => {

    const { GeneratedInvoice, loading } = useSelector((state) => state.InvoiceReducer);
    const { Customers } = useSelector((state) => state.CustomerReducer);
    const { Product } = useSelector((state) => state.ProductReducer);
    const { user } = useSelector((state) => state.UserAuthReducer);

    // modal 
    const [open, setOpen] = useState(false);
    const [showDiscountModal, setShowDiscountModal] = useState(false);
    const [showExtraModal, setShowExtraModal] = useState(false);
    const [showBusinessModal, setShowBusinessModal] = useState(false);
    const [showProductModal, setShowProductModal] = useState(false);
    const [showAddFromListModal, setShowAddFromListModal] = useState(false);
    const [showAddMoreCustomerModal, setShowAddMoreCustomerModal] = useState(false);
    const [showBusinessFromListModal, setShowBusinessFromListModal] = useState(false);

    const [gstin, setGstin] = useState('');
    const [discountper, setDiscountper] = useState('');
    const [discountAmount, setDiscountAmount] = useState(0);
    const [chargename, setChargename] = useState('');
    const [chargeamount, setChargeamount] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState(Customers[0]);
    const [selectedProduct, setSelectedProduct] = useState(Product[0]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [chargeName, setChargeName] = useState('');
    const [chargeAmount, setChargeAmount] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState('Paid'); // Default value 'Paid'
    const [paymentType, setPaymentType] = useState('Cash');
    const [invoiceDate, setInvoiceDate] = useState(new Date().toLocaleDateString());
    const [dueDate, setDueDate] = useState('');
    const generateInvoiceNumber = () => {
        const currentYear = new Date().getFullYear();
        const lastInvoiceNumber = getLastInvoiceNumber(); // Fetch last invoice number from database or storage
        if (lastInvoiceNumber) {
            const [year, number] = lastInvoiceNumber.split('.');
            if (parseInt(year) === currentYear) {
                const nextNumber = parseInt(number) + 1;
                return `${currentYear}.${nextNumber.toString().padStart(4, '0')}`;
            }
        }
        return `${currentYear}.0001`;
    };

    const getLastInvoiceNumber = () => {
        // Logic to fetch last invoice number from database or storage
        // For example:
        return localStorage.getItem('lastInvoiceNumber');
    };

    const saveInvoiceNumber = (invoiceNumber) => {
        // Logic to save last invoice number to database or storage
        // For example:
        localStorage.setItem('lastInvoiceNumber', invoiceNumber);
    };

    const [invoiceNumber, setInvoiceNumber] = useState(generateInvoiceNumber());

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllCustomerRequest());
        dispatch(GetAllProductRequest());
    }, [dispatch]);

    // Check if Product is an array before filtering
    useEffect(() => {
        if (Array.isArray(Product)) {
            setFilteredProducts(Product.filter(product =>
                product.itemName.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        }
    }, [Product, searchTerm]);

    // Function to handle product selection
    const handleSelectProduct = (product) => {
        setSelectedProduct(product);
    };

    // Filtered customers based on search term
    const filteredCustomers = Customers.filter((customer) => {
        return customer.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    // Function to handle saving discount
    const handleSaveDiscount = () => {
        const discountPercentage = parseFloat(discountper);  // Parse discountper to a number
        // Check if discountper is a valid number
        if (!isNaN(discountPercentage)) {
            // Calculate discount amount
            const calculatedDiscountAmount = (selectedProduct?.salePrice * discountPercentage) / 100;
            setDiscountAmount(calculatedDiscountAmount);
        } else {
            setDiscountAmount(0);
        }
        setShowDiscountModal(false); // Close the discount modal
    };

    // Function to handle saving extra charge
    const handleSaveExtraCharge = () => {
        setShowExtraModal(false); // Close the extra charge modal
        setChargeName(chargename);
        setChargeAmount(chargeamount);
    };

    const totalSellingPrice = parseFloat(selectedProduct?.salePrice) - parseFloat(discountAmount) + parseFloat(chargeAmount);

    // Function to handle setting the current date as the invoice date
    const handleSetInvoiceDate = () => {
        setInvoiceDate(new Date().toLocaleDateString());
    };


    // create invoice
    const handleGenerateInvoice = () => {
        const selectedCustomerDetails = {
            ...selectedCustomer,
            name: selectedCustomer?.name,
            state: selectedCustomer?.state,
            address: selectedCustomer?.address,
        };

        const senderDetails = {
            company: user?.businessName,
            address1: user?.address1,
            address2: user?.address2,
        };

        // Construct product object with additional details
        const productDetails = {
            ...selectedProduct,
            discountAmount: discountAmount,
            chargeName: chargeName,
            chargeAmount: chargeAmount,
            totalSellingPrice: totalSellingPrice,
        };

        const invoiceData = {
            sender: senderDetails,
            client: selectedCustomerDetails, // Include the selected customer's name
            products: [productDetails],
            information: {
                invoiceNumber: invoiceNumber,
                invoiceDate: invoiceDate,
                dueDate: dueDate,
            },
            paymentStatus: paymentStatus,
            paymentType: paymentType,
        };
        console.log('invoiceData', invoiceData);
        dispatch(generateInvoiceRequest(invoiceData));
        handleClickOpen();
    };

    // open invoice popup
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // add from list
    const handleAddFromListModal = () => {
        setShowProductModal(false);
        setShowAddFromListModal(true);
    };

    // add more from list business customer modal
    const handleBusinessAFList = () => {
        setShowBusinessModal(false);
        setShowBusinessFromListModal(true);
    };

    // add more from list business customer modal
    const handleAddMoreCustomer = () => {
        setShowBusinessModal(false);
        setShowAddMoreCustomerModal(true);
    };

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: "#F7F7F7"
        }}>
            <SyncLoader color="#011135" />
        </div>
    }

    return (
        <div className='createinvoice'>
            <div className='createinvoice-cont'>
                <div className="left-ci">
                    <Sidenav />
                </div>
                <div className="right-ci">
                    <div className="rci-header">Sale Invoice</div>

                    <div className='rci'>
                        {/*  */}
                        <div className="top-rci">
                            <div className="topleft-rci">

                                <div className='single-rci'>
                                    <div className='single-rci-row'>
                                        <span>Invoice no</span>
                                        <span>{invoiceNumber}</span>
                                    </div>
                                    <div className="single-rci-row">
                                        <span>Invoice Date</span>
                                        <span>{invoiceDate}</span>
                                        <button>Add</button>
                                    </div>
                                    <div className="single-rci-row">
                                        <span>Due Date</span>
                                        <input
                                            type='date'
                                            value={dueDate}
                                            onChange={(e) => setDueDate(e.target.value)}
                                        />
                                        <button>Edit</button>
                                    </div>
                                </div>

                                <div className='single-rci'>
                                    <label className='srci-select-label-flex'>
                                        Customer/ Business name
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={filteredCustomers}
                                            getOptionLabel={(customer) => customer.name}
                                            sx={{
                                                width: 300,
                                                '& .MuiOutlinedInput-root': { // Target the input root element
                                                    '& fieldset': {
                                                        border: '1px solid rgba(166, 169, 172, 0.70)',
                                                        borderColor: 'rgba(166, 169, 172, 0.70)', // Set your desired border color
                                                    },
                                                    '&:hover fieldset': {
                                                        border: '1px solid rgba(166, 169, 172, 0.70)',
                                                        borderColor: 'rgba(166, 169, 172, 0.70)', // Set your desired hover border color
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: '1px solid rgba(166, 169, 172, 0.70)',
                                                        borderColor: 'rgba(166, 169, 172, 0.70)', // Set your desired focused border color
                                                    },
                                                    borderRadius: "10px"
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Customer/ Business name" sx={{
                                                    '& .MuiInputLabel-root': {
                                                        color: 'var(--080808, #0F0F0F)', // Set your desired label color
                                                    },
                                                }}
                                                />
                                            )}
                                            value={selectedCustomer}
                                            onChange={(event, newValue) => {
                                                setSelectedCustomer(newValue);
                                                setGstin(newValue?.gst);
                                            }}
                                            renderOption={(props, customer) => (
                                                <li {...props}>
                                                    <span>{customer.name}</span>
                                                    {/* Render other customer data as needed */}
                                                </li>
                                            )}
                                        />
                                    </label>
                                    <div>
                                    </div>
                                </div>
                                {/* <button className='rci-add-more-pro-btn' onClick={handleBusinessModal}>ADD MORE Customer/ Business+</button> */}
                                {/* {product && <Product closeProduct={closeProduct} />} */}
                                <Dialog
                                    open={showBusinessModal}
                                    onClose={() => setShowBusinessModal(false)}
                                    fullWidth={true} // Set to true to make the dialog take up the full width
                                    maxWidth="xs"
                                >
                                    <DialogContent>
                                        <div className='ci-addpro-popup-btn-cont'>
                                            <button className='ci-dis-popup-btn' onClick={handleBusinessAFList}>ADD FROM LIST +</button>
                                        </div>
                                        <div className='ci-addpro-popup-input-cont'>
                                            <label>
                                                <input
                                                    type="text"
                                                    placeholder='Search for items'
                                                // value={discountper}
                                                // onChange={(e) => setDiscountper(e.target.value)}
                                                />
                                            </label>
                                        </div>
                                        <div className='ci-addpro-popup-btn-cont'>
                                            <button onClick={handleAddMoreCustomer}>ADD NEW PRODUCT +</button>
                                        </div>
                                    </DialogContent>
                                </Dialog>

                                {/*busines customer add from list popup */}
                                <Dialog
                                    open={showBusinessFromListModal}
                                    onClose={() => setShowBusinessFromListModal(false)}
                                    fullWidth={true} // Set to true to make the dialog take up the full width
                                    maxWidth="xs"
                                >
                                    <DialogContent>
                                        <div className='ci-afl-popup-card'>
                                            <div>
                                                <button></button>
                                                <div>
                                                    <h5>Arjun</h5>
                                                    <h6>Customer</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='ci-afl-popup-card'>
                                            <div>
                                                <button></button>
                                                <div>
                                                    <h5>Arjun</h5>
                                                    <h6>Customer</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </DialogContent>
                                </Dialog>

                                {/* add new customer popup */}
                                <Dialog
                                    open={showAddMoreCustomerModal}
                                    onClose={() => setShowAddMoreCustomerModal(false)}
                                    fullWidth={true} // Set to true to make the dialog take up the full width
                                    maxWidth="xs"
                                >
                                    <DialogContent>
                                        <div className='ci-pop-anc-single'>
                                            <label>
                                                Customer/ Business
                                                <input
                                                    type="text"
                                                // value={discountper}
                                                // onChange={(e) => setDiscountper(e.target.value)}
                                                />
                                            </label>
                                        </div>
                                        <div className='ci-pop-anc-single'>
                                            <label>
                                                Phone no.
                                                <input
                                                    type="tel"
                                                // value={discountper}
                                                // onChange={(e) => setDiscountper(e.target.value)}
                                                />
                                            </label>
                                        </div>
                                        <div className='ci-pop-anc-single'>
                                            <label>
                                                Email Address
                                                <input
                                                    type="email"
                                                // value={discountper}
                                                // onChange={(e) => setDiscountper(e.target.value)}
                                                />
                                            </label>
                                        </div>

                                        <div className='ci-pop-anc-single'>
                                            <label>
                                                GSTIN
                                                <input
                                                    type="text"
                                                // value={discountamount}
                                                // onChange={(e) => setDiscountamount(e.target.value)}
                                                />
                                            </label>
                                            <label>
                                                State
                                                <input
                                                    type="text"
                                                // value={discountamount}
                                                // onChange={(e) => setDiscountamount(e.target.value)}
                                                />
                                            </label>
                                        </div>
                                        <div className='ci-pop-anc-single'>
                                            <label>
                                                Billing /shipping address
                                                <input
                                                    type="text"
                                                // value={discountamount}
                                                // onChange={(e) => setDiscountamount(e.target.value)}
                                                />
                                            </label>
                                        </div>
                                        <div className='ci-pop-anc-radio'>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="paymentDirection"
                                                // placeholder='Enter the Amount'
                                                // value={discountamount}
                                                // onChange={(e) => setDiscountamount(e.target.value)}
                                                />
                                                To be collected from Customer
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="paymentDirection"
                                                // placeholder='Enter the Amount'
                                                // value={discountamount}
                                                // onChange={(e) => setDiscountamount(e.target.value)}
                                                />
                                                To be paid to Customer
                                            </label>
                                            <label>
                                                <input
                                                    type="text"
                                                    placeholder='Enter the Amount'
                                                // value={discountamount}
                                                // onChange={(e) => setDiscountamount(e.target.value)}
                                                />
                                            </label>
                                        </div>

                                        <div className='ci-pop-anc-single'>
                                            <button>Save</button>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                            </div>

                            <div className="topright-rci">

                                <div className='single-rci'>
                                    <label className='single-rci-label'>
                                        Your GSTIN
                                        <input
                                            type="text"
                                            value={selectedCustomer?.gst || ''}
                                            onChange={(e) => setGstin(e.target.value)}
                                        />
                                    </label>
                                    <label className='single-rci-label'>
                                        {/* <label className='srci-select-label-cloumn'> */}
                                        State of Supply
                                        <input type="text"
                                            value={selectedCustomer?.state || ''}
                                        />
                                    </label>
                                </div>

                            </div>
                        </div>

                        <div className="mid-rci">

                            <div className='single-rci'>
                                <h3 className='rdi-product-heading'>Products</h3>
                                <div className='rci-prod-field'>
                                    <label>
                                        Item code/Name
                                        <input
                                            type='text'
                                            value={selectedProduct ? selectedProduct.itemName : ''}
                                            readOnly
                                            onClick={handleAddFromListModal}
                                        />
                                    </label>

                                    <label>
                                        Unit Price
                                        <input
                                            type="number"
                                            value={selectedProduct ? selectedProduct.purchasePrice : ''}
                                            readOnly
                                        />
                                    </label>
                                    <label>
                                        Quantity
                                        <input
                                            type="text"
                                            value={selectedProduct ? selectedProduct.unit : ''}
                                            readOnly
                                        />
                                    </label>

                                    <label>
                                        GST
                                        <input
                                            type='text'
                                            value={selectedProduct ? selectedProduct.gstPercent : ''}
                                            readOnly
                                        />
                                    </label>
                                    <label>
                                        Total Amount
                                        <input
                                            type="number"
                                            value={selectedProduct ? selectedProduct?.purchasePrice * selectedProduct?.unit : ''}
                                            readOnly
                                        />
                                    </label>

                                </div>
                                <button className='rci-add-more-pro-btn' onClick={() => setShowProductModal(true)}>ADD MORE PRODUCTS+</button>
                                {/* {product && <Product closeProduct={closeProduct} />} */}
                                <Dialog
                                    open={showProductModal}
                                    onClose={() => setShowProductModal(false)}
                                    fullWidth={true} // Set to true to make the dialog take up the full width
                                    maxWidth="xs"
                                >
                                    <DialogContent>
                                        <div className='ci-addpro-popup-btn-cont'>
                                            <button className='ci-dis-popup-btn' onClick={handleAddFromListModal}>ADD FROM LIST +</button>
                                        </div>
                                        <div className='ci-addpro-popup-input-cont'>
                                            <label>
                                                <input
                                                    type="text"
                                                    placeholder='Search for items'
                                                // value={discountper}
                                                // onChange={(e) => setDiscountper(e.target.value)}
                                                />
                                            </label>
                                        </div>
                                        <div className='ci-addpro-popup-btn-cont'>
                                            <button onClick={handleClose}>ADD NEW PRODUCT +</button>
                                        </div>
                                    </DialogContent>
                                </Dialog>

                                {/* add from list popup */}
                                <Dialog
                                    open={showAddFromListModal}
                                    onClose={() => setShowAddFromListModal(false)}
                                    fullWidth={true} // Set to true to make the dialog take up the full width
                                    maxWidth="xs"
                                >
                                    <DialogContent>
                                        <div className='afl-pop-search-box'>
                                            <span>
                                                <TfiSearch />
                                            </span>
                                            <input
                                                type='text'
                                                placeholder='Search by name , code '
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                        </div>
                                        {filteredProducts?.map((product) => (
                                            <div className='ci-afl-popup-card' key={product.id} onClick={() => handleSelectProduct(product)}>
                                                <div>
                                                    <button></button>
                                                    <div>
                                                        <h5>{product.itemName}</h5>
                                                        <h6>Product</h6>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span>₹ {product.purchasePrice}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </DialogContent>
                                </Dialog>
                            </div>
                        </div>

                        <div className="bottom-rci">

                            <div className="bl-rci-cont">
                                <div className='single-rci'>
                                    <div className="bleft-rci-single-cont">
                                        <span onClick={() => setShowDiscountModal(true)}>Add Discount <button className='five-plus'>+</button></span>
                                        {/* {showModal && <MyModal closeModal={closeModal} />} */}
                                        <Dialog
                                            open={showDiscountModal}
                                            onClose={() => setShowDiscountModal(false)}
                                            fullWidth={true} // Set to true to make the dialog take up the full width
                                            maxWidth="xs"
                                        >
                                            <DialogContent>
                                                <DialogContentText>
                                                    Add discount
                                                </DialogContentText>
                                                <div className='ci-dis-popup-input-cont'>
                                                    <label>
                                                        Discount %
                                                        <input
                                                            type="number"
                                                            value={discountper}
                                                            onChange={(e) => setDiscountper(e.target.value)}
                                                        />
                                                    </label>
                                                    <label>
                                                        Discount Amount
                                                        <input
                                                            type="number"
                                                            value={discountAmount}
                                                            readOnly
                                                        // onChange={(e) => setDiscountamount(e.target.value)}
                                                        />
                                                    </label>
                                                </div>
                                                <button className='ci-dis-popup-btn' onClick={handleSaveDiscount}>Save</button>
                                            </DialogContent>
                                        </Dialog>

                                    </div>

                                    <div className="bleft-rci-single-cont">
                                        <span onClick={() => setShowExtraModal(true)}>Add Extra Charge <button>+</button></span>
                                        {/* {extra && <MySecondModal close={close} />} */}
                                        <Dialog
                                            open={showExtraModal}
                                            onClose={() => setShowExtraModal(false)}
                                            fullWidth={true} // Set to true to make the dialog take up the full width
                                            maxWidth="xs"
                                        >
                                            <DialogContent>
                                                <DialogContentText>
                                                    Add Extra charge
                                                </DialogContentText>
                                                <div className='ci-dis-popup-input-cont'>
                                                    <label>
                                                        Charge name
                                                        <input
                                                            type="text"
                                                            value={chargename}
                                                            onChange={(e) => setChargename(e.target.value)}
                                                        />
                                                    </label>
                                                    <label>
                                                        Charge Amount
                                                        <input
                                                            type="number"
                                                            value={chargeamount}
                                                            onChange={(e) => setChargeamount(e.target.value)}
                                                        />
                                                    </label>
                                                </div>
                                                <button className='ci-dis-popup-btn' onClick={handleSaveExtraCharge}>Save</button>
                                            </DialogContent>
                                        </Dialog>
                                    </div>

                                    <div className="bleft-rci-single-cont">
                                        <div className='brci-radio-cont'>
                                            <label className="brci-radio-label">
                                                <input
                                                    type="radio"
                                                    name="paymentStatus"
                                                    value="Paid"
                                                    checked={paymentStatus === 'Paid'}
                                                    onChange={(e) => setPaymentStatus(e.target.value)}
                                                />
                                                PAID
                                            </label>
                                            <label className="brci-radio-label">
                                                <input
                                                    type="radio"
                                                    name="paymentStatus"
                                                    value="Unpaid"
                                                    checked={paymentStatus === 'Unpaid'} // Checked if paymentStatus is 'Unpaid'
                                                    onChange={(e) => setPaymentStatus(e.target.value)}
                                                />
                                                UNPAID
                                            </label>
                                        </div>


                                        <div className='brci-pay-type-cont'>
                                            <label>
                                                Payment type
                                                <select
                                                    value={paymentType}
                                                    onChange={(e) => setPaymentType(e.target.value)}
                                                >
                                                    <option value="Cash">Cash</option>
                                                    <option value="Card">Card</option>
                                                    <option value="Cheque">Cheque</option>
                                                    <option value="NEFT/RTGS">NEFT/RTGS</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                    </div>


                                    <div className='rci-tandc-cont'>
                                        <h5>Terms and Conditions</h5>
                                        <img src="/assests/edit icon.png" alt="" />
                                    </div>
                                    <div className='rci-tandc-list'>
                                        <ol type='1'>
                                            <li>ITEMS once sold will not be taken back or Exchanged.</li>
                                            <li>A late fee of 10% of due amount will be added for delayed payments.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="br-rci-cont">
                                <div className='single-rci'>
                                    <div className='br-rci-top-row'>
                                        {/* <h6>Price Break up</h6> */}
                                        <label>
                                            Price Break up
                                            <span><input type="checkbox" />Send SMS To Party</span>
                                        </label>
                                    </div>

                                    <div className='br-rci-second-row'>
                                        <span>Sale Price</span>
                                        <span>₹ {selectedProduct?.salePrice}</span>
                                    </div>

                                    <div className='br-rci-second-row'>
                                        <span>Discount({discountper}%)</span>
                                        <span>₹ {discountAmount.toFixed(2)}</span>
                                    </div>

                                    <div className='br-rci-second-row'>
                                        <span>{chargeName}</span>
                                        <span>₹ {chargeAmount}</span>
                                    </div>

                                    <div className='br-rci-last-row'>
                                        <span>Total Selling Price</span>
                                        <span>₹ {totalSellingPrice}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  */}
                    </div>

                    <div className='ci-btn-cont'>
                        <button variant="outlined"
                            onClick={handleGenerateInvoice}
                        // onClick={handleClickOpen}
                        >Create Invoice</button>
                    </div>
                    <Dialog
                        // fullWidth
                        // fullHeight
                        // maxWidth={maxWidth}
                        open={open}
                        onClose={handleClose}
                        fullWidth={true} // Set to true to make the dialog take up the full width
                        maxWidth="xs"
                        PaperProps={{ style: { maxWidth: '300px', borderRadius: '20px' } }}
                    >
                        <DialogContent>
                            <DialogActions style={{ justifyContent: 'center' }}>
                                <Button variant='contained' onClick={handleClose} style={{ backgroundColor: '#F1F1F1', color: '#212429' }}>Close</Button>
                                <Button variant='contained' onClick={() => navigate(`/download-invoice/${GeneratedInvoice?.invoiceId}`)} style={{ backgroundColor: '#011135', color: '#FFF' }} >Open Invoice</Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>

                </div>
            </div>
        </div >
    )
}

export default Createinvoice











// import * as React from 'react';
// import { useState } from 'react'
// import './createinvoice.css'
// import Sidenav from '../../components/Sidenav/Sidenav'
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import { TfiSearch } from 'react-icons/tfi';
// import { useNavigate } from "react-router-dom";
// import { generateInvoiceRequest } from '../../redux/actions/InvoiceAction';
// import { useDispatch, useSelector } from "react-redux";
// import { SyncLoader } from "react-spinners";
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';

// const Createinvoice = () => {

//     const [gstin, setGstin] = useState('');
//     const [discountper, setDiscountper] = useState('');
//     const [discountamount, setDiscountamount] = useState('');
//     const [chargename, setChargename] = useState('');
//     const [chargeamount, setChargeamount] = useState('');

//     const navigate = useNavigate();

//     const [showModal, setShowModal] = useState(false);
//     const closeModal = () => setShowModal(false);

//     const [extra, setExtra] = useState(false);
//     const close = () => setExtra(false);

//     const [payment, setPayment] = useState(false);
//     const closepayment = () => setPayment(false);

//     const [sms, setSMS] = useState(false);
//     const closesms = () => setSMS(false);

//     const [customer, setCustomer] = useState(false);
//     const closecustomer = () => setCustomer(false);

//     const [product, setProduct] = useState(false);
//     const closeProduct = () => setProduct(false);

//     const [showDiscountModal, setShowDiscountModal] = useState(false);
//     const [showExtraModal, setShowExtraModal] = useState(false);
//     const [open, setOpen] = useState(false);

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const handleDiscountClick = () => {
//         setShowDiscountModal(true);
//     };

//     const handleExtraClick = () => {
//         setShowExtraModal(true);
//     };

//     // add more product popup
//     const [showProductModal, setShowProductModal] = useState(false);
//     const handleProductModal = () => {
//         setShowProductModal(true);
//     };

//     // add from list
//     const [showAddFromListModal, setShowAddFromListModal] = useState(false);
//     const handleAddFromListModal = () => {
//         setShowProductModal(false);
//         setShowAddFromListModal(true);
//     };

//     // add more business customer modal
//     const [showBusinessModal, setShowBusinessModal] = useState(false);
//     const handleBusinessModal = () => {
//         setShowBusinessModal(true);
//     };

//     // add more from list business customer modal
//     const [showBusinessFromListModal, setShowBusinessFromListModal] = useState(false);
//     const handleBusinessAFList = () => {
//         setShowBusinessModal(false);
//         setShowBusinessFromListModal(true);
//     };

//     // add more from list business customer modal
//     const [showAddMoreCustomerModal, setShowAddMoreCustomerModal] = useState(false);
//     const handleAddMoreCustomer = () => {
//         setShowBusinessModal(false);
//         setShowAddMoreCustomerModal(true);
//     };

//     const { GeneratedInvoice, loading } = useSelector((state) => state.InvoiceReducer);
//     const { Customers } = useSelector((state) => state.CustomerReducer);

//     const [searchTerm, setSearchTerm] = useState('');
//     const [selectedCustomer, setSelectedCustomer] = useState(null);

//     // Filtered customers based on search term
//     const filteredCustomers = Customers.filter((customer) => {
//         return customer.name.toLowerCase().includes(searchTerm.toLowerCase());
//     });

//     const dispatch = useDispatch();

//     const handleGenerateInvoice = () => {
//         const invoiceData = {
//             sender: "Nikhil Singh",
//             client: "Keshav Sharma",
//             information: {},
//             products: [],
//         };
//         dispatch(generateInvoiceRequest(invoiceData));
//         handleClickOpen();
//     };

//     if (loading) {
//         return <div style={{
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             height: '100vh',
//             backgroundColor: "#F7F7F7"
//         }}>
//             <SyncLoader color="#011135" />
//         </div>
//     }

//     return (
//         <div className='createinvoice'>
//             <div className='createinvoice-cont'>
//                 <div className="left-ci">
//                     <Sidenav />
//                 </div>
//                 <div className="right-ci">
//                     <div className="rci-header">Sale Invoice</div>

//                     <div className='rci'>
//                         {/*  */}
//                         <div className="top-rci">
//                             <div className="topleft-rci">

//                                 <div className='single-rci'>
//                                     <div className='single-rci-row'>
//                                         <span>Invoice no</span>
//                                         <span>1</span>
//                                     </div>
//                                     <div className="single-rci-row">
//                                         <span>Invoice Date</span>
//                                         <span>4 th MAY, 2023</span>
//                                         <button>Add</button>
//                                     </div>
//                                     <div className="single-rci-row">
//                                         <span>Due Date</span>
//                                         <span>4 th May, 2023</span>
//                                         <button>Edit</button>
//                                     </div>
//                                 </div>

//                                 <div className='single-rci'>
//                                     <label className='srci-select-label-flex'>
//                                         Customer/ Business name
//                                         <Autocomplete
//                                             disablePortal
//                                             id="combo-box-demo"
//                                             options={filteredCustomers}
//                                             getOptionLabel={(customer) => customer.name}
//                                             sx={{
//                                                 width: 300,
//                                                 '& .MuiOutlinedInput-root': { // Target the input root element
//                                                     '& fieldset': {
//                                                         border: '1px solid rgba(166, 169, 172, 0.70)',
//                                                         borderColor: 'rgba(166, 169, 172, 0.70)', // Set your desired border color
//                                                     },
//                                                     '&:hover fieldset': {
//                                                         border: '1px solid rgba(166, 169, 172, 0.70)',
//                                                         borderColor: 'rgba(166, 169, 172, 0.70)', // Set your desired hover border color
//                                                     },
//                                                     '&.Mui-focused fieldset': {
//                                                         border: '1px solid rgba(166, 169, 172, 0.70)',
//                                                         borderColor: 'rgba(166, 169, 172, 0.70)', // Set your desired focused border color
//                                                     },
//                                                     borderRadius: "10px"
//                                                 },
//                                             }}
//                                             renderInput={(params) => (
//                                                 <TextField {...params} label="Customer/ Business name" sx={{
//                                                     '& .MuiInputLabel-root': {
//                                                         color: 'var(--080808, #0F0F0F)', // Set your desired label color
//                                                     },
//                                                 }}
//                                                 />
//                                             )}
//                                             value={selectedCustomer}
//                                             onChange={(event, newValue) => {
//                                                 setSelectedCustomer(newValue);
//                                                 // Set GSTIN and state of the selected customer
//                                                 // Replace 'gstNumber' and 'stateOfSupply' with the actual properties from the customer object
//                                                 setGstin(newValue?.gstNumber);
//                                             }}
//                                             renderOption={(props, customer) => (
//                                                 <li {...props}>
//                                                     <span>{customer.name}</span>
//                                                     <span>{customer.gstNumber}</span>
//                                                     {/* Render other customer data as needed */}
//                                                 </li>
//                                             )}
//                                         />
//                                     </label>
//                                     {/* {customer && <Customer closecustomer={closecustomer} />} */}
//                                     <div>
//                                     </div>
//                                 </div>
//                                 {/* <button className='rci-add-more-pro-btn' onClick={handleBusinessModal}>ADD MORE Customer/ Business+</button> */}
//                                 {/* {product && <Product closeProduct={closeProduct} />} */}
//                                 <Dialog
//                                     open={showBusinessModal}
//                                     onClose={() => setShowBusinessModal(false)}
//                                     fullWidth={true} // Set to true to make the dialog take up the full width
//                                     maxWidth="xs"
//                                 >
//                                     <DialogContent>
//                                         <div className='ci-addpro-popup-btn-cont'>
//                                             <button className='ci-dis-popup-btn' onClick={handleBusinessAFList}>ADD FROM LIST +</button>
//                                         </div>
//                                         <div className='ci-addpro-popup-input-cont'>
//                                             <label>
//                                                 <input
//                                                     type="text"
//                                                     placeholder='Search for items'
//                                                 // value={discountper}
//                                                 // onChange={(e) => setDiscountper(e.target.value)}
//                                                 />
//                                             </label>
//                                         </div>
//                                         <div className='ci-addpro-popup-btn-cont'>
//                                             <button onClick={handleAddMoreCustomer}>ADD NEW PRODUCT +</button>
//                                         </div>
//                                     </DialogContent>
//                                 </Dialog>

//                                 {/*busines customer add from list popup */}
//                                 <Dialog
//                                     open={showBusinessFromListModal}
//                                     onClose={() => setShowBusinessFromListModal(false)}
//                                     fullWidth={true} // Set to true to make the dialog take up the full width
//                                     maxWidth="xs"
//                                 >
//                                     <DialogContent>
//                                         <div className='ci-afl-popup-card'>
//                                             <div>
//                                                 <button></button>
//                                                 <div>
//                                                     <h5>Arjun</h5>
//                                                     <h6>Customer</h6>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className='ci-afl-popup-card'>
//                                             <div>
//                                                 <button></button>
//                                                 <div>
//                                                     <h5>Arjun</h5>
//                                                     <h6>Customer</h6>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </DialogContent>
//                                 </Dialog>

//                                 {/* add new customer popup */}
//                                 <Dialog
//                                     open={showAddMoreCustomerModal}
//                                     onClose={() => setShowAddMoreCustomerModal(false)}
//                                     fullWidth={true} // Set to true to make the dialog take up the full width
//                                     maxWidth="xs"
//                                 >
//                                     <DialogContent>
//                                         <div className='ci-pop-anc-single'>
//                                             <label>
//                                                 Customer/ Business
//                                                 <input
//                                                     type="text"
//                                                 // value={discountper}
//                                                 // onChange={(e) => setDiscountper(e.target.value)}
//                                                 />
//                                             </label>
//                                         </div>
//                                         <div className='ci-pop-anc-single'>
//                                             <label>
//                                                 Phone no.
//                                                 <input
//                                                     type="tel"
//                                                 // value={discountper}
//                                                 // onChange={(e) => setDiscountper(e.target.value)}
//                                                 />
//                                             </label>
//                                         </div>
//                                         <div className='ci-pop-anc-single'>
//                                             <label>
//                                                 Email Address
//                                                 <input
//                                                     type="email"
//                                                 // value={discountper}
//                                                 // onChange={(e) => setDiscountper(e.target.value)}
//                                                 />
//                                             </label>
//                                         </div>

//                                         <div className='ci-pop-anc-single'>
//                                             <label>
//                                                 GSTIN
//                                                 <input
//                                                     type="text"
//                                                 // value={discountamount}
//                                                 // onChange={(e) => setDiscountamount(e.target.value)}
//                                                 />
//                                             </label>
//                                             <label>
//                                                 State
//                                                 <input
//                                                     type="text"
//                                                 // value={discountamount}
//                                                 // onChange={(e) => setDiscountamount(e.target.value)}
//                                                 />
//                                             </label>
//                                         </div>
//                                         <div className='ci-pop-anc-single'>
//                                             <label>
//                                                 Billing /shipping address
//                                                 <input
//                                                     type="text"
//                                                 // value={discountamount}
//                                                 // onChange={(e) => setDiscountamount(e.target.value)}
//                                                 />
//                                             </label>
//                                         </div>
//                                         <div className='ci-pop-anc-radio'>
//                                             <label>
//                                                 <input
//                                                     type="radio"
//                                                     name="paymentDirection"
//                                                 // placeholder='Enter the Amount'
//                                                 // value={discountamount}
//                                                 // onChange={(e) => setDiscountamount(e.target.value)}
//                                                 />
//                                                 To be collected from Customer
//                                             </label>
//                                             <label>
//                                                 <input
//                                                     type="radio"
//                                                     name="paymentDirection"
//                                                 // placeholder='Enter the Amount'
//                                                 // value={discountamount}
//                                                 // onChange={(e) => setDiscountamount(e.target.value)}
//                                                 />
//                                                 To be paid to Customer
//                                             </label>
//                                             <label>
//                                                 <input
//                                                     type="text"
//                                                     placeholder='Enter the Amount'
//                                                 // value={discountamount}
//                                                 // onChange={(e) => setDiscountamount(e.target.value)}
//                                                 />
//                                             </label>
//                                         </div>

//                                         <div className='ci-pop-anc-single'>
//                                             <button>Save</button>
//                                         </div>
//                                     </DialogContent>
//                                 </Dialog>
//                             </div>

//                             <div className="topright-rci">

//                                 <div className='single-rci'>
//                                     <label className='single-rci-label'>
//                                         Your GSTIN
//                                         <input
//                                             type="text"
//                                             value={selectedCustomer?.gst || ''}
//                                             onChange={(e) => setGstin(e.target.value)}
//                                         />
//                                     </label>
//                                     <label className='single-rci-label'>
//                                         {/* <label className='srci-select-label-cloumn'> */}
//                                         State of Supply
//                                         <input type="text"
//                                             value={selectedCustomer?.state || ''}
//                                         />
//                                     </label>
//                                 </div>

//                             </div>
//                         </div>

//                         <div className="mid-rci">

//                             <div className='single-rci'>
//                                 <h3 className='rdi-product-heading'>Products</h3>
//                                 <div className='rci-prod-field'>
//                                     <label>
//                                         Item code/Name
//                                         <select
//                                         // value={selectWeek}
//                                         // onChange={(e) => setSelectWeek(e.target.value)}
//                                         >
//                                             <option value="">Item code/Name</option>
//                                         </select>
//                                     </label>

//                                     <label>
//                                         Unit Price
//                                         <input
//                                             type="number"
//                                             value={gstin}
//                                             onChange={(e) => setGstin(e.target.value)}
//                                         />
//                                     </label>
//                                     <label>
//                                         Quantity
//                                         <input
//                                             type="number"
//                                             value={gstin}
//                                             onChange={(e) => setGstin(e.target.value)}
//                                         />
//                                     </label>

//                                     <label>
//                                         GST
//                                         <select
//                                         // value={selectWeek}
//                                         // onChange={(e) => setSelectWeek(e.target.value)}
//                                         >
//                                             <option value="">Select an GST</option>
//                                         </select>
//                                     </label>
//                                     <label>
//                                         Total Amount
//                                         <input
//                                             type="number"
//                                             value={gstin}
//                                             onChange={(e) => setGstin(e.target.value)}
//                                         />
//                                     </label>

//                                 </div>
//                                 <button className='rci-add-more-pro-btn' onClick={handleProductModal}>ADD MORE PRODUCTS+</button>
//                                 {/* {product && <Product closeProduct={closeProduct} />} */}
//                                 <Dialog
//                                     open={showProductModal}
//                                     onClose={() => setShowProductModal(false)}
//                                     fullWidth={true} // Set to true to make the dialog take up the full width
//                                     maxWidth="xs"
//                                 >
//                                     <DialogContent>
//                                         <div className='ci-addpro-popup-btn-cont'>
//                                             <button className='ci-dis-popup-btn' onClick={handleAddFromListModal}>ADD FROM LIST +</button>
//                                         </div>
//                                         <div className='ci-addpro-popup-input-cont'>
//                                             <label>
//                                                 <input
//                                                     type="text"
//                                                     placeholder='Search for items'
//                                                 // value={discountper}
//                                                 // onChange={(e) => setDiscountper(e.target.value)}
//                                                 />
//                                             </label>
//                                         </div>
//                                         <div className='ci-addpro-popup-btn-cont'>
//                                             <button onClick={handleClose}>ADD NEW PRODUCT +</button>
//                                         </div>
//                                     </DialogContent>
//                                 </Dialog>

//                                 {/* add from list popup */}
//                                 <Dialog
//                                     open={showAddFromListModal}
//                                     onClose={() => setShowAddFromListModal(false)}
//                                     fullWidth={true} // Set to true to make the dialog take up the full width
//                                     maxWidth="xs"
//                                 >
//                                     <DialogContent>
//                                         <div className='afl-pop-search-box'>
//                                             <span>
//                                                 <TfiSearch />
//                                             </span>
//                                             <input
//                                                 type='text'
//                                                 placeholder='Search by name , code '
//                                             // value={searchTerm}
//                                             // onChange={handleInputChange}
//                                             // onClick={handleSearchContainerClick}
//                                             // onFocus={handleSearchContainerClick}
//                                             />
//                                         </div>
//                                         <div className='ci-afl-popup-card'>
//                                             <div>
//                                                 <button></button>
//                                                 <div>
//                                                     <h5>Bottle</h5>
//                                                     <h6>Product</h6>
//                                                 </div>
//                                             </div>
//                                             <div>
//                                                 <span>₹ 499</span>
//                                             </div>
//                                         </div>
//                                     </DialogContent>
//                                 </Dialog>
//                             </div>
//                         </div>

//                         <div className="bottom-rci">

//                             <div className="bl-rci-cont">
//                                 <div className='single-rci'>
//                                     <div className="bleft-rci-single-cont">
//                                         <span onClick={handleDiscountClick}>Add Discount <button className='five-plus'>+</button></span>
//                                         {/* {showModal && <MyModal closeModal={closeModal} />} */}
//                                         <Dialog
//                                             open={showDiscountModal}
//                                             onClose={() => setShowDiscountModal(false)}
//                                             fullWidth={true} // Set to true to make the dialog take up the full width
//                                             maxWidth="xs"
//                                         >
//                                             <DialogContent>
//                                                 <DialogContentText>
//                                                     Add discount
//                                                 </DialogContentText>
//                                                 <div className='ci-dis-popup-input-cont'>
//                                                     <label>
//                                                         Discount %
//                                                         <input
//                                                             type="number"
//                                                             value={discountper}
//                                                             onChange={(e) => setDiscountper(e.target.value)}
//                                                         />
//                                                     </label>
//                                                     <label>
//                                                         Discount Amount
//                                                         <input
//                                                             type="number"
//                                                             value={discountamount}
//                                                             onChange={(e) => setDiscountamount(e.target.value)}
//                                                         />
//                                                     </label>
//                                                 </div>
//                                                 <button className='ci-dis-popup-btn' onClick={handleClose}>Save</button>
//                                             </DialogContent>
//                                         </Dialog>

//                                     </div>

//                                     <div className="bleft-rci-single-cont">
//                                         <span onClick={handleExtraClick}>Add Extra Charge <button>+</button></span>
//                                         {/* {extra && <MySecondModal close={close} />} */}
//                                         <Dialog
//                                             open={showExtraModal}
//                                             onClose={() => setShowExtraModal(false)}
//                                             fullWidth={true} // Set to true to make the dialog take up the full width
//                                             maxWidth="xs"
//                                         >
//                                             <DialogContent>
//                                                 <DialogContentText>
//                                                     Add Extra charge
//                                                 </DialogContentText>
//                                                 <div className='ci-dis-popup-input-cont'>
//                                                     <label>
//                                                         Charge name
//                                                         <input
//                                                             type="text"
//                                                             value={chargename}
//                                                             onChange={(e) => setChargename(e.target.value)}
//                                                         />
//                                                     </label>
//                                                     <label>
//                                                         Charge Amount
//                                                         <input
//                                                             type="number"
//                                                             value={chargeamount}
//                                                             onChange={(e) => setChargeamount(e.target.value)}
//                                                         />
//                                                     </label>
//                                                 </div>
//                                                 <button className='ci-dis-popup-btn'>Save</button>
//                                             </DialogContent>
//                                         </Dialog>
//                                     </div>

//                                     <div className="bleft-rci-single-cont">
//                                         <div className='brci-radio-cont'>
//                                             <label className="brci-radio-label">
//                                                 <input type="radio" name="paymentStatus" value="paid" />
//                                                 PAID
//                                             </label>
//                                             <label className="brci-radio-label">
//                                                 <input type="radio" name="paymentStatus" value="unpaid" />
//                                                 UNPAID
//                                             </label>
//                                         </div>


//                                         <div className='brci-pay-type-cont'>
//                                             <label>
//                                                 Payment type
//                                                 <select
//                                                 // value={selectWeek}
//                                                 // onChange={(e) => setSelectWeek(e.target.value)}
//                                                 >
//                                                     <option value="Cash">Cash</option>
//                                                     <option value="Card">Card</option>
//                                                     <option value="Cheque">Cheque</option>
//                                                     <option value="NEFT/RTGS">NEFT/RTGS</option>
//                                                 </select>
//                                             </label>
//                                         </div>
//                                     </div>
//                                     <div>
//                                     </div>


//                                     <div className='rci-tandc-cont'>
//                                         <h5>Terms and Conditions</h5>
//                                         <img src="/assests/edit icon.png" alt="" />
//                                     </div>
//                                     <div className='rci-tandc-list'>
//                                         <ol type='1'>
//                                             <li>ITEMS once sold will not be taken back or Exchanged.</li>
//                                             <li>A late fee of 10% of due amount will be added for delayed payments.</li>
//                                         </ol>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="br-rci-cont">
//                                 <div className='single-rci'>
//                                     <div className='br-rci-top-row'>
//                                         {/* <h6>Price Break up</h6> */}
//                                         <label>
//                                             Price Break up
//                                             <span><input type="checkbox" />Send SMS To Party</span>
//                                         </label>
//                                     </div>

//                                     <div className='br-rci-second-row'>
//                                         <span>Sale Price</span>
//                                         <span>₹ 499</span>
//                                     </div>

//                                     <div className='br-rci-second-row'>
//                                         <span>Discount(20%)</span>
//                                         <span>₹ 99.00</span>
//                                     </div>

//                                     <div className='br-rci-second-row'>
//                                         <span>IGST @ 5%</span>
//                                         <span>₹19.96</span>
//                                     </div>

//                                     <div className='br-rci-last-row'>
//                                         <span>Total Selling Price</span>
//                                         <span>₹ 419.16</span>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         {/*  */}
//                     </div>

//                     <div className='ci-btn-cont'>
//                         <button variant="outlined"
//                             onClick={handleGenerateInvoice}
//                         // onClick={handleClickOpen}
//                         >Create Invoice</button>
//                     </div>
//                     <Dialog
//                         // fullWidth
//                         // fullHeight
//                         // maxWidth={maxWidth}
//                         open={open}
//                         onClose={handleClose}
//                         fullWidth={true} // Set to true to make the dialog take up the full width
//                         maxWidth="xs"
//                         PaperProps={{ style: { maxWidth: '300px', borderRadius: '20px' } }}
//                     >
//                         <DialogContent>
//                             <DialogActions style={{ justifyContent: 'center' }}>
//                                 <Button variant='contained' onClick={handleClose} style={{ backgroundColor: '#F1F1F1', color: '#212429' }}>Close</Button>
//                                 <Button variant='contained' onClick={() => navigate(`/download-invoice/${GeneratedInvoice?.invoiceId}`)} style={{ backgroundColor: '#011135', color: '#FFF' }} >Open Invoice</Button>
//                             </DialogActions>
//                         </DialogContent>
//                     </Dialog>

//                 </div>
//             </div>
//         </div >
//     )
// }

// export default Createinvoice