import {
    ADD_CUSTOMER_FAIL,
    ADD_CUSTOMER_REQUEST,
    ADD_CUSTOMER_SUCCESS,
    GET_ALL_CUSTOMER_FAIL,
    GET_ALL_CUSTOMER_REQUEST,
    GET_ALL_CUSTOMER_SUCCESS,
    GET_CUSTOMER_FAIL,
    GET_CUSTOMER_REQUEST,
    GET_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_STATUS_FAIL,
    UPDATE_CUSTOMER_STATUS_REQUEST,
    UPDATE_CUSTOMER_STATUS_SUCCESS
} from '../constants/CustomerConstant';

const initialState = {
    Customer: {},
    loading: false,
    error: null,
    Customers: []
};

const CustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CUSTOMER_REQUEST:
        case GET_CUSTOMER_REQUEST:
        case GET_ALL_CUSTOMER_REQUEST:
        case UPDATE_CUSTOMER_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case ADD_CUSTOMER_SUCCESS:
        case GET_CUSTOMER_SUCCESS:
        case UPDATE_CUSTOMER_STATUS_SUCCESS:
            return {
                ...state,
                Customer: action.data,
                error: null,
                loading: false
            };

        case GET_ALL_CUSTOMER_SUCCESS:
            return {
                ...state,
                Customers: action.data,
                error: null,
                loading: false
            };

        case ADD_CUSTOMER_FAIL:
        case GET_CUSTOMER_FAIL:
        case GET_ALL_CUSTOMER_FAIL:
        case UPDATE_CUSTOMER_STATUS_FAIL:
            return {
                ...state,
                Customer: null,
                error: action.error,
                loading: false
            };

        default:
            return state;
    }
};

export default CustomerReducer;
