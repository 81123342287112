import { combineReducers } from 'redux';
import UserAuthReducer from './AuthReducer';
import CustomerReducer from './CustomerReducer';
import SupplierReducer from './SupplierReducer';
import ProductReducer from './ProductReducer';
import ServiceReducer from './ServiceReducer';
import InvoiceReducer from './InvoiceReducer';

const rootReducer = combineReducers({
    UserAuthReducer,
    CustomerReducer,
    SupplierReducer,
    ProductReducer,
    ServiceReducer,
    InvoiceReducer,
});

export default rootReducer;
