import React from 'react'
import "./productheader.css"
import { Link, useLocation } from 'react-router-dom'

const Productheader = () => {

    const location = useLocation();

    return (
        <div className='productheader'>
            <div className="productheader-cont">
                <Link to="/products" className='ph-link'>
                    <button
                        className={`prohead-btn ${location.pathname === '/products' || location.pathname === '/sale-products' ? 'active' : ''
                            }`}
                    >
                        Products
                    </button>
                </Link>
                <Link to="/services" className='ph-link'>
                    <button
                        className={`prohead-btn ${location.pathname === '/services' || location.pathname === '/add-service' ? 'active' : ''
                            }`}
                    >
                        Services
                    </button>
                </Link>

            </div>
        </div>
    )
}

export default Productheader