import * as React from 'react';
import { useState } from 'react'
import './customercard.css'
import { TfiSearch } from 'react-icons/tfi';
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import CSheader from '../../components/CSheader/CSheader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { FaArrowDown, FaIndianRupeeSign } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GetAllCustomerRequest, UpdateCustomerStatusRequest } from '../../redux/actions/CustomerAction';

const Customercard = ({ handleAdd }) => {

    const { Customers } = useSelector((state) => state.CustomerReducer);

    const [open, setOpen] = useState(false);
    const [openGotModal, setOpenGotModal] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(Customers[0]);
    const [searchTerm, setSearchTerm] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [paymentType, setPaymentType] = useState('cash');

    const dispatch = useDispatch(); // Initialize useDispatch

    const handleGaveClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // payment switch
    const [selectedMode, setSelectedMode] = useState('cash');

    const handlePaymentModeChange = () => {
        setPaymentType((prevType) => (prevType === 'cash' ? 'online' : 'cash'));
    };

    const filteredCustomers = Customers.filter((customer) => {
        // Perform case-insensitive search based on customer name
        return customer.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const handleSelectCustomer = (customer) => {
        setSelectedCustomer(customer);
    };

    const handleUpdatePaymentStatus = () => {

        const customerData = {
            customerId: selectedCustomer._id,
            paymentDate: paymentDate,
            paymentType: paymentType,
            toBeCollected: !selectedCustomer.toBeCollected
        };

        dispatch(UpdateCustomerStatusRequest(customerData));
        // Reset payment date and type after dispatching the action
        dispatch(GetAllCustomerRequest());
        setPaymentDate('');
        setPaymentType('cash');
    };

    return (
        <div className='customercard'>
            <div className="customercard-cont">
                <div className="left-cc">

                    <div className='left-cc-cont'>
                        <CSheader />
                        <div className='lcc-search-cont'>
                            <div className='lcc-search-box'>
                                <span>
                                    <TfiSearch />
                                </span>
                                <input
                                    type='text'
                                    placeholder='Search'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='lcust-card-loop' >
                            {/* {Customers?.map((customer) => ( */}
                            {filteredCustomers.map((customer) => (
                                <div className='lcust-card' key={customer._id} onClick={() => handleSelectCustomer(customer)}>
                                    <div className="leftcust-card">
                                        <div>
                                            <h5 className='left-cc-logo'>{customer?.name?.split(' ')[0].charAt(0).toUpperCase()}</h5>
                                            <button className='lcc-edit-btn'>
                                                Edit <FaRegEdit />
                                            </button>
                                        </div>
                                        <div className='lcc-lp-cont'>
                                            <h6>{customer.name}</h6>
                                            <p>{new Date(customer.createdAt).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                        </div>
                                    </div>
                                    <div className="left-right-cc-card">
                                        <div className='lcc-customer-text-cont'>
                                            <span>Customer</span>
                                        </div>
                                        <div className='lcc-cust-price-cont'>
                                            <button style={{ color: customer.toBeCollected ? '#009A34' : '#DD2121' }}><FaIndianRupeeSign /> {customer.amount} <FaArrowDown /></button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='lcc-add-customer-btn'>
                            <button onClick={() => handleAdd()}>Add Customer</button>
                        </div>
                    </div>

                </div>


                <div className="right-cc">

                    <div className='rcc-cont'>
                        <div className='top-rcc' >
                            <div className="top-rcc-cont">
                                <div className='tleft-rcc-cont'>
                                    <h5>{selectedCustomer?.name?.split(' ')[0].charAt(0).toUpperCase()}</h5>
                                    <div>
                                        <span>{selectedCustomer.name}</span>
                                        <p>{new Date(selectedCustomer.createdAt).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                    </div>
                                </div>
                                <div className='tright-rcc-cont'>
                                    <button> <FaRegEdit />Edit Profile</button>
                                    <button><AiOutlineDelete /> Delete</button>
                                </div>
                            </div>

                            <div className='bottom-trcc-cont'>
                                <div>
                                    <h6>{selectedCustomer.toBeCollected ? 'You Got' : 'You Gave'}</h6>
                                    <span style={{ color: selectedCustomer.toBeCollected ? '#009A34' : '#DD2121' }} ><FaIndianRupeeSign /> {selectedCustomer.amount}</span>
                                </div>
                            </div>

                        </div>

                        <div className='mid-rcc' >
                            <div>
                                <img src="/assests/whatsapp.png" alt="" />
                                <span>Reminder</span>
                            </div>
                            <div>
                                <img src="/assests/sms.png" alt="" />
                                <span>SMS Reminder</span>
                            </div>
                        </div>

                        <div className='bottom-rcc' >
                            <div className='rcc-tran-cont'>
                                <div className="rcc-ttran-header">
                                    <h6>Transactions</h6>
                                </div>
                                <div className="rcc-tran-bottom">
                                    <div>
                                        <h6>Sale Draft</h6>
                                        <span>{new Date(selectedCustomer.createdAt).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                    </div>
                                    <div>
                                        <p><FaIndianRupeeSign /> {selectedCustomer.amount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='rcc-btn-cont' >
                            <button className='rcc-stockin-btn' onClick={() => setOpenGotModal(true)} >
                                You Got
                            </button>
                            <button className='rcc-stockout-btn' onClick={handleGaveClick} >
                                You Gave
                            </button>

                            <Dialog
                                open={open}
                                onClose={handleClose}
                                fullWidth={true} // Set to true to make the dialog take up the full width
                                maxWidth="xs"
                            >
                                <DialogContent>
                                    <div className='cc-popup-toppay-cont'>
                                        <h4 style={{ color: selectedCustomer.toBeCollected ? '#009A34' : '#DD2121' }}><FaIndianRupeeSign /></h4>
                                    </div>
                                    <div className='cc-popup-top-input-cont'>
                                        <input type="text" placeholder='Enter details' />
                                    </div>
                                    <div className='cc-popup-pm-cont'>
                                        <label htmlFor="paymentSwitch">
                                            Payment mode
                                            {/* <!-- Rounded switch --> */}
                                            {/* cash
                                            <label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                            online */}
                                            <Stack direction="row" alignItems="center">
                                                <Typography>cash</Typography>
                                                <Switch
                                                    checked={paymentType === 'online'}
                                                    onChange={handlePaymentModeChange}
                                                    inputProps={{ 'aria-label': 'ant design' }}
                                                />
                                                <Typography>Online</Typography>
                                            </Stack>
                                        </label>

                                        <label className='cc-date-label'>
                                            <input type='date' value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)} />
                                        </label>

                                    </div>
                                    <button className='cc-popup-save-btn' onClick={handleUpdatePaymentStatus}>Save</button>
                                    <DialogActions style={{ justifyContent: 'center' }}>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>

                            <Dialog
                                open={openGotModal}
                                onClose={() => setOpenGotModal(false)}
                                fullWidth={true} // Set to true to make the dialog take up the full width
                                maxWidth="xs"
                            >
                                <DialogContent>
                                    <div className='cc-popup-toppay-cont'>
                                        <h4 style={{ color: selectedCustomer.toBeCollected ? '#009A34' : '#DD2121' }}><FaIndianRupeeSign /></h4>
                                    </div>
                                    <div className='cc-popup-top-input-cont'>
                                        <input type="text" placeholder='Enter details' />
                                    </div>
                                    <div className='cc-popup-pm-cont'>
                                        <label htmlFor="paymentSwitch">
                                            Payment mode
                                            <Stack direction="row" alignItems="center">
                                                <Typography>cash</Typography>
                                                <Switch
                                                    checked={paymentType === 'online'}
                                                    onChange={handlePaymentModeChange}
                                                    inputProps={{ 'aria-label': 'ant design' }}
                                                />
                                                <Typography>Online</Typography>
                                            </Stack>
                                        </label>

                                        <label className='cc-date-label'>
                                            <input type='date' value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)} />
                                        </label>

                                    </div>
                                    <button className='cc-popup-save-btn' onClick={handleUpdatePaymentStatus}>Save</button>
                                </DialogContent>
                            </Dialog>

                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default Customercard