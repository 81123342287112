import React, { useEffect, useState } from 'react'
import './supplier.css'
import Sidenav from '../../components/Sidenav/Sidenav'
import CSheader from '../../components/CSheader/CSheader'
import Suppliercard from '../SupplierCard/Suppliercard'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux'
import { SyncLoader } from 'react-spinners'
import {
  AddSupplierRequest,
  GetAllSupplierRequest,
} from "../../redux/actions/SupplierAction";
import { toast } from 'react-toastify'
import validator from 'validator'

const Supplier = () => {

  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [gst, setGst] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [paymentDirection, setPaymentDirection] = useState("");
  const [amount, setAmount] = useState("");

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch(); // Initialize useDispatch

  const { loading, Suppliers } = useSelector((state) => state.SupplierReducer);

  useEffect(() => {
    dispatch(GetAllSupplierRequest());
  }, [dispatch]);

  const handleAddSupplierOpen = () => {
    setOpen(true);
  };

  const handleAddSupplierClose = () => {
    setOpen(false);
  };

  const handleAddSupplier = async (e) => {
    e.preventDefault();
    try {
      // Validations
      if (
        !name ||
        !phoneNo ||
        !email ||
        !gst ||
        !state ||
        !address ||
        !paymentDirection ||
        !amount
      ) {
        toast.error("All fields are required");
      } else if (!validator.isMobilePhone(phoneNo, "en-IN")) {
        toast.error("Invalid Phone Number");
      } else if (!validator.isEmail(email)) {
        toast.error("Invalid Email");
      } else {
        const customerData = {
          name,
          phoneNo,
          email,
          gst,
          state,
          address,
          paymentDirection,
          amount,
          toBeCollected:
            paymentDirection === "To be collected from customer" ? true : false, // Set to true if paymentDirection is 'To be collected from customer', otherwise false
        };

        dispatch(AddSupplierRequest(customerData));
      }
    } catch (error) {
      console.error(
        `${error?.response?.data?.error || "Something Went Wrong"}`
      );
      // toast.error(`${error?.response?.data?.error || 'Something Went Wrong'}`);
    }
    handleAddSupplierClose();
  };

  if (loading) {
    return <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: "#F7F7F7"
    }}>
      <SyncLoader color="#011135" />
    </div>
  }

  return (
    <div className='supplier'>
      <div className="supplier-cont">
        <div className="left-supplier">
          <Sidenav />
        </div>
        <div className="right-supplier">
          {
            Suppliers.length > 0 ? (
              <Suppliercard handleAdd={handleAddSupplierOpen} />
            ) : (
              <div className='rsup-home-cont'>
                <CSheader />
                <div className='rsup-home-img-cont'>
                  <img src='/assests/supplier home.png' alt='' />
                </div>
                <div className='rsup-home-text-cont' >
                  <h1>Add your first Supplier!</h1>
                  <h3>Add your different supplier and effortlessly track payment out and Purchase returns</h3>
                  <button onClick={handleAddSupplierOpen}>ADD +</button>
                </div>
              </div>
            )}

        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true} // Set to true to make the dialog take up the full width
        maxWidth="xs"
      >
        <DialogContent>
          <div className='supform-single-cont'>
            <label>
              Supplier/ Business
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
          </div>
          <div className='supform-single-cont'>
            <label>
              Phone no.
              <input
                type="tel"
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
              />
            </label>
          </div>
          <div className='supform-single-cont'>
            <label>
              Email Address
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
          </div>

          <div className='supform-single-cont'>
            <label>
              GSTIN
              <input
                type="text"
                value={gst}
                onChange={(e) => setGst(e.target.value)}
              />
            </label>
            <label>
              State
              <input
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </label>
          </div>
          <div className='supform-single-cont'>
            <label>
              Billing /shipping address
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </label>
          </div>
          <div className='supform-bott-popup-cont'>
            <label>
              <input
                type="radio"
                name="paymentDirection"
                checked={
                  paymentDirection === "To be collected from customer"
                }
                onChange={() =>
                  setPaymentDirection("To be collected from customer")
                }
              />
              To be collected from Supplier
            </label>
            <label>
              <input
                type="radio"
                name="paymentDirection"
                checked={
                  paymentDirection === "To be paid to customer"
                }
                onChange={() =>
                  setPaymentDirection("To be paid to customer")
                }
              />
              To be paid to Supplier
            </label>
            <label>
              <input
                type="text"
                placeholder='Enter the Amount'
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </label>
          </div>

          <div className='supform-single-cont'>
            <button onClick={handleAddSupplier}>Save</button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Supplier