import React, { useEffect, useState } from 'react'
import './service.css'
import Sidenav from '../../components/Sidenav/Sidenav'
import Productheader from '../../components/ProductHeader/Productheader'
import Addservice from '../AddService/Addservice'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
    AddServiceRequest,
    GetAllServiceRequest,
} from "../../redux/actions/ServiceAction";
import { SyncLoader } from 'react-spinners'

const Service = () => {

    const [open, setOpen] = useState(false);
    const [isTaxChecked, setIsTaxChecked] = useState(false);

    const [serviceName, setServiceName] = useState("");
    const [serviceCategory, setServiceCategory] = useState("");
    const [servicePrice, setServicePrice] = useState("");
    const [serviceUnit, setServiceUnit] = useState("");
    const [HsnSacCode, setHsnSacCode] = useState("");
    const [gstPercent, setGstPercent] = useState("");

    const dispatch = useDispatch();

    const { loading, Service } = useSelector((state) => state.ServiceReducer);

    useEffect(() => {
        dispatch(GetAllServiceRequest());
    }, [dispatch]);

    const handleAddServiceOpen = () => {
        setOpen(true);
    };

    const handleAddServiceClose = () => {
        setOpen(false);
    };

    const handleTaxCheckboxChange = () => {
        setIsTaxChecked(!isTaxChecked);
    };

    const handleAddService = () => {
        try {
            if (
                !serviceName ||
                !serviceCategory ||
                !servicePrice ||
                !serviceUnit ||
                !setGstPercent ||
                !HsnSacCode
            ) {
                toast.error("require all fields");
            }
            const serviceData = {
                serviceName,
                serviceCategory,
                servicePrice,
                serviceUnit,
                HsnSacCode,
                gstPercent,
            };
            dispatch(AddServiceRequest(serviceData));
        } catch (error) {
            console.log(error);
        }
        setOpen(false);
    };

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: "#F7F7F7"
        }}>
            <SyncLoader color="#011135" />
        </div>
    }

    return (
        <div className='service'>
            <div className="servicet-cont">
                <div className="left-service">
                    <Sidenav />
                </div>
                <div className="right-service">

                    {/* service page for first entry */}
                    {Service?.length > 0 ? (
                        <Addservice handleAddService={handleAddServiceOpen}/>
                    ) : (
                        <div className='rservice-home-cont'>
                            <Productheader />
                            <div className='rservice-home-img-cont'>
                                <img src='/assests/service.png' alt='' />
                            </div>
                            <div className='rservice-home-text-cont' >
                                <h1>Add your first Service!</h1>
                                <h3>Manage services and prices</h3>
                                <button type="button" onClick={handleAddServiceOpen}>ADD +</button>
                            </div>
                        </div>
                    )}
                    {/* add service card */}
                    {/* <Addservice /> */}

                    <Dialog
                        open={open}
                        onClose={() => setOpen(false)}
                        fullWidth={true} // Set to true to make the dialog take up the full width
                        maxWidth="xs"
                    >
                        <DialogContent>
                            <div className='serform-single-cont ser-popup-chek-cont'>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isTaxChecked}
                                        onChange={handleTaxCheckboxChange}
                                    />
                                    Tax
                                </label>
                            </div>
                            <div className='serform-single-cont'>
                                <label>
                                    Service name
                                    <input
                                        type="text"
                                        value={serviceName}
                                        onChange={(e) => setServiceName(e.target.value)}
                                    />
                                </label>
                            </div>

                            <div className='serform-single-cont'>
                                <label>
                                    Service Price
                                    <input
                                        type="number"
                                        value={servicePrice}
                                        onChange={(e) => setServicePrice(e.target.value)}
                                    />
                                </label>
                                <label>
                                    Unit
                                    <select
                                        value={serviceUnit}
                                        onChange={(e) => setServiceUnit(e.target.value)}
                                    >
                                        <option value="">Unit</option>
                                        <option value="Day">Day</option>
                                        <option value="Hours">Hours</option>
                                        <option value="Minutes">Minutes</option>
                                        <option value="Months">Months</option>
                                        <option value="Sittings">Sittings</option>
                                    </select>
                                </label>
                            </div>
                            <div className='serform-single-cont'>
                                <label>
                                    Service Category
                                    <input
                                        type="text"
                                        value={serviceCategory}
                                        onChange={(e) => setServiceCategory(e.target.value)}
                                    />
                                </label>

                            </div>
                            <div className='serform-single-cont'>
                                <label>
                                    HSN/SAC Code
                                    <input
                                        type="text"
                                        value={HsnSacCode}
                                        onChange={(e) => setHsnSacCode(e.target.value)}
                                    />
                                </label>
                                {isTaxChecked && (
                                    <label>
                                        Tax %
                                        <select
                                            value={gstPercent}
                                            onChange={(e) => setGstPercent(e.target.value)}
                                        >
                                            <option value="">No Gst</option>
                                            <option value="Gst@0%">Gst@0%</option>
                                            <option value="Gst@3%">Gst@3%</option>
                                            <option value="Gst@5%">Gst@5%</option>
                                            <option value="Gst@12%">Gst@12%</option>
                                            <option value="Gst@18%">Gst@18%</option>
                                            <option value="Gst@28%">Gst@28%</option>
                                        </select>
                                    </label>
                                )}
                            </div>

                            <div className='serform-single-cont'>
                                <button onClick={handleAddService}>Save</button>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </div >
    )
}

export default Service