import React, { useEffect, useState } from 'react'
import './product.css'
import Sidenav from '../../components/Sidenav/Sidenav'
import Productheader from '../../components/ProductHeader/Productheader'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Saleproduct from '../SaleProduct/Saleproduct';
import { toast } from 'react-toastify';
import { AddProductRequest, GetAllProductRequest } from '../../redux/actions/ProductAction';
import { useDispatch, useSelector } from 'react-redux';
import { SyncLoader } from 'react-spinners';

const Product = () => {

    const [open, setOpen] = useState(false);
    const [isTaxChecked, setIsTaxChecked] = useState(false);

    const [itemName, setItemName] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [purchasePrice, setPurchasePrice] = useState('');
    const [unit, setUnit] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [itemCategory, setItemCategory] = useState('');
    const [hsnSacCode, setHsnSacCode] = useState('');
    const [gstPercent, setGstPercent] = useState('');

    const handleAddProductOpen = () => {
        setOpen(true);
    };

    const handleAddProductClose = () => {
        setOpen(false);
    };

    const handleTaxCheckboxChange = () => {
        setIsTaxChecked(!isTaxChecked);
    };

    const dispatch = useDispatch(); // Initialize useDispatch

    const { loading, Product } = useSelector((state) => state.ProductReducer);

    useEffect(() => {
        dispatch(GetAllProductRequest());
    }, [dispatch]);

    const handleAddProduct = async (e) => {
        e.preventDefault();
        try {
            const productData = {
                itemName,
                salePrice,
                purchasePrice,
                unit,
                itemCode,
                itemCategory,
                hsnSacCode,
                gstPercent,
            };

            // Validations
            if (!itemName || !hsnSacCode || !salePrice || !purchasePrice || !unit || !itemCode || !itemCategory) {
                toast.error("All fields are required");
            } else {
                dispatch(AddProductRequest(productData));
                handleAddProductClose();
            }
        } catch (error) {
            console.error(`${error?.response?.data?.error || 'Something Went Wrong'}`);
            // toast.error(`${error?.response?.data?.error || 'Something Went Wrong'}`);
        }
    }

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: "#F7F7F7"
        }}>
            <SyncLoader color="#011135" />
        </div>
    }

    return (
        <div className='product'>
            <div className="product-cont">

                <div className="left-product">
                    <Sidenav />
                </div>

                <div className="right-product">
                    {Product && Product?.length > 0 ? (
                        <Saleproduct handleAddProduct={handleAddProductOpen} />
                    ) : (
                        <div className='rprod-home-cont'>
                            <Productheader />
                            <div className='rprod-home-img-cont'>
                                <img src='/assests/product.png' alt='' />
                            </div>
                            <div className='rprod-home-text-cont' >
                                <h1>Add your First Product!</h1>
                                <h3> Manage your stoks and get low stock alerts</h3>
                                <button type="button" onClick={handleAddProductOpen}>ADD +</button>
                            </div>
                        </div>
                    )}

                </div>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    fullWidth={true} // Set to true to make the dialog take up the full width
                    maxWidth="xs"
                >
                    <DialogContent>
                        <div className='proform-single-cont product-popup-chek-cont'>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={isTaxChecked}
                                    onChange={handleTaxCheckboxChange}
                                />
                                Tax
                            </label>
                        </div>
                        <div className='proform-single-cont'>
                            <label>
                                Item name
                                <input
                                    type="text"
                                    value={itemName}
                                    onChange={(e) => setItemName(e.target.value)}
                                />
                            </label>
                        </div>

                        <div className='proform-single-cont'>
                            <label>
                                Sale price
                                <input
                                    type="number"
                                    value={salePrice}
                                    onChange={(e) => setSalePrice(e.target.value)}
                                />
                            </label>
                            <label>
                                Purchase Price
                                <input
                                    type="number"
                                    value={purchasePrice}
                                    onChange={(e) => setPurchasePrice(e.target.value)}
                                />
                            </label>
                            <label>
                                Unit
                                <input
                                    type="number"
                                    value={unit}
                                    onChange={(e) => setUnit(e.target.value)}
                                />
                                {/* <select
                                    value={unit}
                                    onChange={(e) => setUnit(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    <option value="Pieces(pcs)">Pieces(pcs)</option>
                                    <option value="Bags(bag)">Bags(bag)</option>
                                    <option value="Meters(m)">Meters(m)</option>
                                    <option value="Liters(L)">Liters(L)</option>
                                    <option value="Kilograms(kg)">Kilograms(kg)</option>
                                    <option value="Grams(g)">Grams(g)</option>
                                    <option value="Cartons(ctn)">Cartons(ctn)</option>
                                    <option value="Pairs">Pairs</option>
                                </select> */}
                            </label>
                        </div>
                        <div className='proform-single-cont'>
                            <label>
                                Item Code
                                <input
                                    type="text"
                                    value={itemCode}
                                    onChange={(e) => setItemCode(e.target.value)}
                                />
                            </label>

                        </div>
                        <div className='proform-single-cont'>
                            <label>
                                Item Category
                                <input
                                    type="text"
                                    value={itemCategory}
                                    onChange={(e) => setItemCategory(e.target.value)}
                                />
                            </label>

                        </div>
                        <div className='proform-single-cont'>
                            <label>
                                HSN/SAC Code
                                <input
                                    type="text"
                                    value={hsnSacCode}
                                    onChange={(e) => setHsnSacCode(e.target.value)}
                                />
                            </label>
                            {isTaxChecked && (
                                <label>
                                    Tax %
                                    <select
                                        value={gstPercent}
                                        onChange={(e) => setGstPercent(e.target.value)}
                                    >
                                        <option value="">No Gst</option>
                                        <option value="Gst@0%">Gst@0%</option>
                                        <option value="Gst@3%">Gst@3%</option>
                                        <option value="Gst@5%">Gst@5%</option>
                                        <option value="Gst@12%">Gst@12%</option>
                                        <option value="Gst@18%">Gst@18%</option>
                                        <option value="Gst@28%">Gst@28%</option>
                                    </select>
                                </label>
                            )}
                        </div>

                        <div className='proform-single-cont'>
                            <button onClick={handleAddProduct}>Save</button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}

export default Product