import React, { useEffect, useState } from 'react'
import './profile.css'
import Sidenav from '../../components/Sidenav/Sidenav'
import { useNavigate } from 'react-router-dom/dist'
import { SyncLoader } from 'react-spinners'
import { useSelector, useDispatch } from 'react-redux'
import { getUserRequest } from '../../redux/actions/AuthAction'

const Profile = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [initials, setInitials] = useState('');

    const { user, loading } = useSelector((state) => state.UserAuthReducer);

    useEffect(() => {
        dispatch(getUserRequest())
    }, [dispatch])

    useEffect(() => {
        if (user && user?.businessName) {
            const firstLetter = user?.businessName.charAt(0).toUpperCase();
            setInitials(firstLetter);
        }
    }, [user]);

    const handleEditProfile = (e) => {
        e.preventDefault()
        navigate('/edit-profile');
    };

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: "#F7F7F7"
        }}>
            <SyncLoader color="#011135" />
        </div>
    }

    return (
        <div className='profile'>
            <div className="profile-cont">
                <div className="left-profile">
                    <Sidenav />
                </div>
                <div className="right-profile">
                    <div className="right-profile-header">
                        <h3>
                            Profile Setup
                        </h3>
                    </div>
                    {user && (
                        <div className="bottom-rprofile">
                            <div className="rleft-profile">
                                <div className="rleft-profile-card">
                                    {/* left container details */}
                                    <div className="rl-profile-logo-cont">
                                        <h3>{initials}</h3>
                                        <h4>{user?.businessName}</h4>
                                    </div>

                                    <div className="rl-profile-detail-cont">
                                        <div>
                                            <h5 className='rprofile-head-text'>Phone no.</h5>
                                            <h6 className='rprofile-head-text-name'>{user?.phoneNo}</h6>
                                        </div>
                                        <div>
                                            <h5 className='rprofile-head-text'>Email</h5>
                                            <h6 className='rprofile-head-text-name'>{user?.email}</h6>
                                        </div>
                                        <div>
                                            <h5 className='rprofile-head-text'>Website</h5>
                                            <h6 className='rprofile-head-text-name'>{user?.website}</h6>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="rright-profile">

                                <div className="rright-profile-card">
                                    <div className="rright-profile-top-btn-cont">
                                        <div>
                                            <h5 className='rprofile-head-text'>Name</h5>
                                            <h6 className='rprofile-head-text-name'>{user?.name}</h6>
                                        </div>
                                        <button onClick={handleEditProfile}>Edit Profile</button>
                                    </div>

                                </div>

                                <div className="rright-profile-card">
                                    <div className="rright-profile-top-btn-cont">
                                        <div>
                                            <h5 className='rprofile-head-text'>GSTIN</h5>
                                            <h6 className='rprofile-head-text-name'>{user?.gst}</h6>
                                        </div>
                                        <div>
                                            <h5 className='rprofile-head-text'>State</h5>
                                            <h6 className='rprofile-head-text-name'>{user?.state}</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="rright-profile-card">
                                    <div className="rright-profile-top-btn-cont">
                                        <div>
                                            <h5 className='rprofile-head-text'>Address</h5>
                                            <h6 className='rprofile-head-text-name'>{user?.address1} {user?.address2}</h6>
                                        </div>
                                    </div>

                                </div>

                                <div className="rright-profile-card">
                                    <div className="rright-profile-bd-cont">
                                        <h5 className='rprofile-head-text'>Bank Details</h5>
                                    </div>

                                    <div className='rr-profilecard-table'>
                                        <table>
                                            <tr>
                                                <td className='rprofile-head-text'>Bank name</td>
                                                <td className='rprofile-head-text-name'>{user?.bankName}</td>
                                            </tr>
                                            <tr>
                                                <td className='rprofile-head-text'>Account no</td>
                                                <td className='rprofile-head-text-name'>{user?.accountNo}</td>
                                            </tr>
                                            <tr>
                                                <td className='rprofile-head-text'>Branch</td>
                                                <td className='rprofile-head-text-name'>{user?.branch}</td>
                                            </tr>
                                            <tr>
                                                <td className='rprofile-head-text'>IFSC</td>
                                                <td className='rprofile-head-text-name'>{user?.ifsc}</td>
                                            </tr>
                                            <tr>
                                                <td className='rprofile-head-text'>UPI ID</td>
                                                <td className='rprofile-head-text-name'>{user?.upiId}</td>
                                            </tr>
                                        </table>
                                    </div>

                                </div>

                            </div>

                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}

export default Profile