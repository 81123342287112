import {
    ADD_PRODUCT_FAIL,
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_SUCCESS,
    GET_ALL_PRODUCT_FAIL,
    GET_ALL_PRODUCT_REQUEST,
    GET_ALL_PRODUCT_SUCCESS,
    GET_PRODUCT_FAIL,
    GET_PRODUCT_REQUEST,
    GET_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_STOCK_STATUS_FAIL,
    UPDATE_PRODUCT_STOCK_STATUS_REQUEST,
    UPDATE_PRODUCT_STOCK_STATUS_SUCCESS
} from '../constants/ProductConstant';

const initialState = {
    ProductDetail: {},
    Product: {},
    loading: false,
    error: null
};

const ProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PRODUCT_REQUEST:
        case GET_PRODUCT_REQUEST:
        case GET_ALL_PRODUCT_REQUEST:
        case UPDATE_PRODUCT_STOCK_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case ADD_PRODUCT_SUCCESS:
        case GET_ALL_PRODUCT_SUCCESS:
            return {
                ...state,
                Product: action.data,
                error: null,
                loading: false
            };

        case GET_PRODUCT_SUCCESS:
        case UPDATE_PRODUCT_STOCK_STATUS_SUCCESS:
            return {
                ...state,
                ProductDetail: action.data,
                error: null,
                loading: false
            };

        case ADD_PRODUCT_FAIL:
        case GET_ALL_PRODUCT_FAIL:
        case UPDATE_PRODUCT_STOCK_STATUS_FAIL:
            return {
                ...state,
                Product: null,
                error: action.error,
                loading: false
            };

        case GET_PRODUCT_FAIL:
            return {
                ...state,
                ProductDetail: null,
                error: action.error,
                loading: false
            };

        default:
            return state;
    }
};

export default ProductReducer;
