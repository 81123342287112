import {
    ADD_SERVICE_FAIL,
    ADD_SERVICE_REQUEST,
    ADD_SERVICE_SUCCESS,
    GET_ALL_SERVICE_FAIL,
    GET_ALL_SERVICE_REQUEST,
    GET_ALL_SERVICE_SUCCESS,
    GET_SERVICE_FAIL,
    GET_SERVICE_REQUEST,
    GET_SERVICE_SUCCESS
} from '../constants/ServiceConstant';

const initialState = {
    ServiceDetail: {},
    Service: {},
    loading: false,
    error: null
};

const ServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SERVICE_REQUEST:
        case GET_SERVICE_REQUEST:
        case GET_ALL_SERVICE_REQUEST:
            return {
                ...state,
                loading: true
            };

        case ADD_SERVICE_SUCCESS:
        case GET_ALL_SERVICE_SUCCESS:
            return {
                ...state,
                Service: action.data,
                error: null,
                loading: false
            };

        case GET_SERVICE_SUCCESS:
            return {
                ...state,
                ServiceDetail: action.data,
                error: null,
                loading: false
            };

        case ADD_SERVICE_FAIL:
        case GET_ALL_SERVICE_FAIL:
            return {
                ...state,
                Service: null,
                error: action.error,
                loading: false
            };

        case GET_SERVICE_FAIL:
            return {
                ...state,
                ServiceDetail: null,
                error: action.error,
                loading: false
            };

        default:
            return state;
    }
};

export default ServiceReducer;
