import {
    ADD_SUPPLIER_FAIL,
    ADD_SUPPLIER_REQUEST,
    ADD_SUPPLIER_SUCCESS,
    GET_ALL_SUPPLIER_FAIL,
    GET_ALL_SUPPLIER_REQUEST,
    GET_ALL_SUPPLIER_SUCCESS,
    GET_SUPPLIER_FAIL,
    GET_SUPPLIER_REQUEST,
    GET_SUPPLIER_SUCCESS,
    UPDATE_SUPPLIER_STATUS_FAIL,
    UPDATE_SUPPLIER_STATUS_REQUEST,
    UPDATE_SUPPLIER_STATUS_SUCCESS
} from '../constants/SupplierConstant';

const initialState = {
    Supplier: {},
    Suppliers: [],
    loading: false,
    error: null
};

const SupplierReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SUPPLIER_REQUEST:
        case GET_SUPPLIER_REQUEST:
        case GET_ALL_SUPPLIER_REQUEST:
        case UPDATE_SUPPLIER_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case ADD_SUPPLIER_SUCCESS:
        case GET_SUPPLIER_SUCCESS:
        case UPDATE_SUPPLIER_STATUS_SUCCESS:
            return {
                ...state,
                Supplier: action.data,
                error: null,
                loading: false
            };

        case GET_ALL_SUPPLIER_SUCCESS:
            return {
                ...state,
                Suppliers: action.data,
                error: null,
                loading: false
            };

        case ADD_SUPPLIER_FAIL:
        case GET_SUPPLIER_FAIL:
        case GET_ALL_SUPPLIER_FAIL:
        case UPDATE_SUPPLIER_STATUS_FAIL:
            return {
                ...state,
                Supplier: null,
                error: action.error,
                loading: false
            };

        default:
            return state;
    }
};

export default SupplierReducer;
