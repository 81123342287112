import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_GET_REQUEST,
    USER_GET_SUCCESS,
    USER_GET_FAIL,
    USER_EDIT_PROFILE_REQUEST,
    USER_EDIT_PROFILE_FAIL,
    USER_EDIT_PROFILE_SUCCESS,
    USER_LOGOUT_REQUEST,
    USER_LOGOUT_SUCCESS,
    USER_LOGOUT_FAIL,
    CLEAR_ERRORS,
} from '../constants/AuthConstant';

export const UserRegisterRequest = (userData, navigate) => ({
    type: USER_REGISTER_REQUEST,
    payload: { userData, navigate }
    // payload,
});

export const UserRegisterSuccess = (data) => ({
    type: USER_REGISTER_SUCCESS,
    data,
});

export const UserRegisterFailure = (error) => ({
    type: USER_REGISTER_FAIL,
    error,
});

export const UserLoginRequest = (userData, navigate) => ({
    type: USER_LOGIN_REQUEST,
    payload: { userData, navigate }
});

export const UserLoginSuccess = (data) => ({
    type: USER_LOGIN_SUCCESS,
    data,
});

export const UserLoginFailure = (error) => ({
    type: USER_LOGIN_FAIL,
    error,
});

export const getUserRequest = (payload) => ({
    type: USER_GET_REQUEST,
    payload,
});

export const getUserSuccess = (data) => ({
    type: USER_GET_SUCCESS,
    data,
});

export const getUserFailure = (error) => ({
    type: USER_GET_FAIL,
    error,
});

export const UserEditProfileRequest = (payload) => ({
    type: USER_EDIT_PROFILE_REQUEST,
    payload,
});

export const UserEditProfileSuccess = (data) => ({
    type: USER_EDIT_PROFILE_SUCCESS,
    data,
});

export const UserEditProfileFailure = (error) => ({
    type: USER_EDIT_PROFILE_FAIL,
    error,
});

export const UserLogoutRequest = (payload) => ({
    type: USER_LOGOUT_REQUEST,
    payload,
});

export const UserLogoutSuccess = (data) => ({
    type: USER_LOGOUT_SUCCESS,
    data,
});

export const UserLogoutFailure = (error) => ({
    type: USER_LOGOUT_FAIL,
    error,
});


export const clearErrors = () => ({
    type: CLEAR_ERRORS
});